/* ======== 5.2 Project CSS ========*/
.project-tab {
    display: flex;
    justify-content: center;
    @media #{$large-mobile} {
        display: inline-table;
        text-align: center;
    }
    &.nav-tab {
        @media #{$large-mobile} {
            display: flex;
            text-align: center;
        }
    }
    & .project-filter, .nav-link {
        font-size: 13px;
        border: 0;
        color: #666666;
        font-weight: 500;
        padding: 15px 25px;
        margin-left: 5px;
        letter-spacing: .5px;
        line-height: 1.2;
        background: $white;
        margin-bottom: 5px;
        @media #{$large-mobile} {
            padding: 12px 15px;
        }

        &:focus {
            outline: none;
        }
        &:hover, &.is-checked {
            background: $primary;
            color: $white;
        }
    }
    & .nav-link {
        color: $white;
        border: 1px solid $white;
        background: transparent;

        &:hover, &.active {
            background: $primary;
            color: $white;
            border: 1px solid $primary;
        }
    }
}
.project-image {
    & img {
        width: 100%;
        height: 100%;
    }
}
.project-content {
    margin-top: 27px;
    & .title {
        font-size: 20px;
        line-height: 1;
        font-weight: 500;
        & a {
            color: $white;
            &:hover {
                color: $primary;
            }
        }
    }
    & .link {
        font-size: 14px;
        font-family: $headings-font-family;
        line-height: 1;
        text-transform: uppercase;
        transition: $transition-base;
        color: $white;
        font-weight: 500;
        display: block;
        text-decoration: underline;
        letter-spacing: .6px;
        
        &:hover {
            color: $primary;
            text-decoration: underline;
            transition: $transition-base;
        }
    }

    &.project-content-dark {
        & .title {
            & a {
                color: $secondary;
                &:hover {
                    color: $primary;
                }
            }
        }

        & .project-tag {
            & li {
                &:after {
                    color: $secondary;
                }

                & a {
                    color: $secondary;
                    &:hover {
                        color: $primary;
                        text-decoration: underline;
                        transition: $transition-base;
                    }
                }
            }
        }
    }
}
.project-tag {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    & li {
        &:after {
            content: "/";
            padding: 0 5px;
            color: $white;
        }
        & a {
            color: $white;
            font-size: 12px;
            text-transform: uppercase;
            transition: $transition-base;
            font-weight: 400;
            letter-spacing: .5px;
            &:hover {
                color: $primary;
                text-decoration: underline;
                transition: $transition-base;
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
.tab-carousel{
    &:hover {

        & .swiper-button-next, 
        & .swiper-button-prev {
            opacity: 1;
            visibility: visible;
            transition: $transition-base;
        }
    }
}
.project-carousel {
    position: relative;

    & .swiper-button-prev {
        left: -80px;
    }

    & .swiper-button-next {
        right: -80px;
    }
    
    @media #{
        $laptop-device, 
        $desktop-device,
        $tablet-device,
        $large-mobile
    } {
        & .swiper-button-prev {
            left: 0px;
        }
    
        & .swiper-button-next {
            right: 0px;
        }
    }

    & .swiper-button-next, 
    & .swiper-button-prev {
        &:after {
            display: none;
        }

        border: 1px solid $white;
        border-radius: 100%;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 18px;
        line-height: 18px;
        transition: $transition-base;
        opacity: 0;
        visibility: hidden;

        &:focus {
            outline: none;
        }

        &:hover {
            background: $primary;
            border-color: $primary;
            transition: $transition-base;
        }
    }

}
.single-project-fullwidth {

    & .project-content {
        text-align: center;
        margin-top: 0;
        padding: 30px 0;
        background: #f1f1f1;

        & .title {
            & a {
                color: #5f5f5f;
                &:hover {
                    color: $primary;
                }
            }
        }
    }

    & .project-tag {
        justify-content: center;
        & li {
            &:after {
                color: #5f5f5f;
            }
            & a {
                color: #5f5f5f;
                &:hover {
                    color: $primary;
                }
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}
.project-overlay {
    position: relative;
    display: block;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        color: $white;
    }

    img {
        transition: 0.4s;
    }
}
.single-project {
    &:hover {
        .project-overlay {
            &:before {
                opacity: 1;
                visibility: visible;
            }

            img {
                transform: scale(1.1) rotate(1.2deg);
                transition: 0.4s; 
            }
        }
    }
}
.project-info, .project-desc {
    
    .title {
        font-size: 22px;
    }

    li {
        margin-bottom: 5px;
        line-height: 1.8;
        span {
            font-weight: 700;
            font-size: 16px;
            margin-right: 5px;
        }
    }
}

