/* ======== 4.5 Brand Logo CSS ========*/
.brand-logo-bg {
    padding: 100px 0px;
    
    @media #{$tablet-device} {
        padding: 80px 0px;
    }
    @media #{$large-mobile} {
        padding: 60px 0px;
    }
}