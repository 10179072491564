/* ======== 4.2 Team CSS ========*/
.single-team-member {
    border: 1px solid $gray-200;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        background: $primary;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 0%;
        transition: $transition-base;
        opacity: 0;
        visibility: hidden;
    }

    &:hover {
        &:before {
            opacity: 1;
            visibility: visible;
            width: 100%;
        }
    }
}
.team-member-image {
    & img {
        width: 100%;
    }
}
.team-member-content {
    padding: 28px 0 20px;
    text-align: center;
    .name{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        color: $headings-color;
        display: block;
        line-height: 1;
        margin-bottom: 10px;
    }
    .person-title{
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 400;
        font-family: $headings-font-family;
        display: block;
        line-height: 1;
        margin-bottom: 10px;
        color: $headings-color;
    }
    .social-widgets{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & li {
            & a {
                padding: 10px 15px;
            }
        }
    }
}