/*-----------------------------------------------------------------------------------

    Template Name: Template Name
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header CSS
        2.1 Header Top
        2.2 Header Bottom
        2.3 Mobile Menu
        2.4 Offcanvas Search
    03. Hero CSS
    04. Component CSS
        4.1 Button CSS
        4.2 Team CSS
        4.3 Funfact CSS
        4.4 Testimonial CSS
        4.5 Brand Logo CSS
        4.6 Breadcrumb CSS
        4.7 Pagination CSS
    05. Pages CSS
        5.1 About CSS
        5.2 Project CSS 
        5.3 Blog CSS 
        5.4 Contact CSS 
        5.5 Faq CSS 
        5.6 Error 404 CSS
    06. Footer CSS
        6.1 Footer Top CSS
        6.2 Footer Bottom CSS

-----------------------------------------------------------------------------------*/
/*!
 * Bootstrap v5.0.0-alpha2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #ffffff;
  --bs-gray: #686868;
  --bs-gray-dark: #2b2b35;
  --bs-primary: #ffb300;
  --bs-secondary: #2b2b35;
  --bs-default-color: #666666;
  --bs-light: #ffffff;
  --bs-matterhorn: #4d4d4d;
  --bs-gray: #f7f7f7;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.929;
  color: #666666;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.3;
  color: #000000;
}

h1, .h1 {
  font-size: calc(1.34375rem + 1.125vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.1875rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.27812rem + 0.3375vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.53125rem;
  }
}

h4, .h4 {
  font-size: calc(1.25625rem + 0.075vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.3125rem;
  }
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #666666;
  text-decoration: none;
}

a:hover {
  color: #ffb300;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  -ms-overflow-style: scrollbar;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #2e2e2e;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #686868;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  white-space: normal;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #686868;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #ececec;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #686868;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 30px;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9375rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9375rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.625rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.625rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.875rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.875rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2.1875rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2.1875rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.8125rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.8125rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 3.125rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 3.125rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #666666;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #666666;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #666666;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #666666;
  vertical-align: top;
  border-color: #ececec;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
  border-bottom-width: 1px;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #ffeab8;
  --bs-table-striped-bg: #f2deaf;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6d3a6;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ecd8aa;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e6d3a6;
}

.table-secondary {
  --bs-table-bg: #c4c4c6;
  --bs-table-striped-bg: #bababc;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #b0b0b2;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #b5b5b7;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #b0b0b2;
}

.table-success {
  --bs-table-bg: #bfddcf;
  --bs-table-striped-bg: #b5d2c5;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #acc7ba;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #b1ccbf;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #acc7ba;
}

.table-info {
  --bs-table-bg: #bbf0fb;
  --bs-table-striped-bg: #b2e4ee;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #a8d8e2;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #addee8;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #a8d8e2;
}

.table-warning {
  --bs-table-bg: #ffeeba;
  --bs-table-striped-bg: #f2e2b1;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6d6a7;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ecdcac;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #e6d6a7;
}

.table-danger {
  --bs-table-bg: #f5c6cb;
  --bs-table-striped-bg: #e9bcc1;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #ddb2b7;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e3b7bc;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #ddb2b7;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000000;
  color: #000000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #2b2b35;
  --bs-table-striped-bg: #36363f;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #404049;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #3b3b44;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #404049;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.929;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76563rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #686868;
}

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.929em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.929;
  color: #666666;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #f7f7f7;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:focus {
  color: #666666;
  background-color: #ffffff;
  border-color: #ffd980;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.form-control::placeholder {
  color: #686868;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #dcd9d9;
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.929;
  color: #666666;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.929em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.form-control-lg {
  min-height: calc(1.929em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem;
}

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.929em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.929;
  color: #666666;
  vertical-align: middle;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232b2b35' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #f7f7f7;
  border-radius: 0.25rem;
  appearance: none;
}

.form-select:focus {
  border-color: #ffd980;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.form-select:focus::-ms-value {
  color: #666666;
  background-color: #ffffff;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #686868;
  background-color: #dcd9d9;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #666666;
}

.form-select-sm {
  height: calc(1.929em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
}

.form-select-lg {
  height: calc(1.929em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.form-check {
  display: block;
  min-height: 1.68788rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.4645em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #ffd980;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.form-check-input:checked {
  background-color: #ffb300;
  border-color: #ffb300;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #ffb300;
  border-color: #ffb300;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffd980'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.form-file {
  --bs-form-file-height: calc(1.929em + 0.75rem + 2px);
  position: relative;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--bs-form-file-height);
  margin: 0;
  opacity: 0;
}

.form-file-input:focus-within ~ .form-file-label {
  border-color: #ffd980;
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.form-file-input[disabled] ~ .form-file-label .form-file-text,
.form-file-input:disabled ~ .form-file-label .form-file-text {
  background-color: #dcd9d9;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: var(--bs-form-file-height);
  border-color: #f7f7f7;
  border-radius: 0.25rem;
}

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.929;
  color: #666666;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #ffffff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.929;
  color: #666666;
  background-color: #dcd9d9;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-file-sm {
  --bs-form-file-height: calc(1.929em + 0.5rem + 2px);
  font-size: 0.76563rem;
}

.form-file-sm .form-file-text,
.form-file-sm .form-file-button {
  padding: 0.25rem 0.5rem;
}

.form-file-lg {
  --bs-form-file-height: calc(1.929em + 1rem + 2px);
  font-size: 1.09375rem;
}

.form-file-lg .form-file-text,
.form-file-lg .form-file-button {
  padding: 0.5rem 1rem;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: none;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ffb300;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #ffe8b3;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #ececec;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ffb300;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #ffe8b3;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #ececec;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  background-color: #ffb300;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    transition: none;
  }
}

.form-range::-ms-thumb:active {
  background-color: #ffe8b3;
}

.form-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.form-range::-ms-fill-lower {
  background-color: #ececec;
  border-radius: 1rem;
}

.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #ececec;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #8c8c8c;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #8c8c8c;
}

.form-range:disabled::-ms-thumb {
  background-color: #8c8c8c;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-file .form-file-input:focus ~ .form-file-label {
  z-index: 3;
}

.input-group > .form-file > .form-file-input:focus {
  z-index: 4;
}

.input-group > .form-file:not(:last-child) > .form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-file:not(:first-child) > .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.929;
  color: #666666;
  text-align: center;
  white-space: nowrap;
  background-color: #dcd9d9;
  border: 1px solid #f7f7f7;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control {
  min-height: calc(1.929em + 1rem + 2px);
}

.input-group-lg > .form-select {
  height: calc(1.929em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control {
  min-height: calc(1.929em + 0.5rem + 2px);
}

.input-group-sm > .form-select {
  height: calc(1.929em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #ffffff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.929em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.48225em + 0.1875rem) center;
  background-size: calc(0.9645em + 0.375rem) calc(0.9645em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.929em + 0.75rem);
  background-position: top calc(0.48225em + 0.1875rem) right calc(0.48225em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232b2b35' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.9645em + 0.375rem) calc(0.9645em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #198754;
}

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.929em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.48225em + 0.1875rem) center;
  background-size: calc(0.9645em + 0.375rem) calc(0.9645em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.929em + 0.75rem);
  background-position: top calc(0.48225em + 0.1875rem) right calc(0.48225em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232b2b35' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.9645em + 0.375rem) calc(0.9645em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #dc3545;
}

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.929;
  color: #666666;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #666666;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #000000;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-primary:hover {
  color: #000000;
  background-color: #ffbe26;
  border-color: #ffbb1a;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000000;
  background-color: #ffbe26;
  border-color: #ffbb1a;
  box-shadow: 0 0 0 0.25rem rgba(217, 152, 0, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #000000;
  background-color: #ffc233;
  border-color: #ffbb1a;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 152, 0, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #000000;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-secondary {
  color: #ffffff;
  background-color: #2b2b35;
  border-color: #2b2b35;
}

.btn-secondary:hover {
  color: #ffffff;
  background-color: #1a1a20;
  border-color: #141419;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #ffffff;
  background-color: #1a1a20;
  border-color: #141419;
  box-shadow: 0 0 0 0.25rem rgba(75, 75, 83, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #141419;
  border-color: #0e0e12;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 75, 83, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #ffffff;
  background-color: #2b2b35;
  border-color: #2b2b35;
}

.btn-default-color {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.btn-default-color:hover {
  color: #ffffff;
  background-color: #535353;
  border-color: #4d4d4d;
}

.btn-check:focus + .btn-default-color, .btn-default-color:focus {
  color: #ffffff;
  background-color: #535353;
  border-color: #4d4d4d;
  box-shadow: 0 0 0 0.25rem rgba(125, 125, 125, 0.5);
}

.btn-check:checked + .btn-default-color,
.btn-check:active + .btn-default-color, .btn-default-color:active, .btn-default-color.active,
.show > .btn-default-color.dropdown-toggle {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #464646;
}

.btn-check:checked + .btn-default-color:focus,
.btn-check:active + .btn-default-color:focus, .btn-default-color:active:focus, .btn-default-color.active:focus,
.show > .btn-default-color.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(125, 125, 125, 0.5);
}

.btn-default-color:disabled, .btn-default-color.disabled {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.btn-light {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-light:hover {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-matterhorn {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-matterhorn:hover {
  color: #ffffff;
  background-color: #3a3a3a;
  border-color: #343434;
}

.btn-check:focus + .btn-matterhorn, .btn-matterhorn:focus {
  color: #ffffff;
  background-color: #3a3a3a;
  border-color: #343434;
  box-shadow: 0 0 0 0.25rem rgba(104, 104, 104, 0.5);
}

.btn-check:checked + .btn-matterhorn,
.btn-check:active + .btn-matterhorn, .btn-matterhorn:active, .btn-matterhorn.active,
.show > .btn-matterhorn.dropdown-toggle {
  color: #ffffff;
  background-color: #343434;
  border-color: #2d2d2d;
}

.btn-check:checked + .btn-matterhorn:focus,
.btn-check:active + .btn-matterhorn:focus, .btn-matterhorn:active:focus, .btn-matterhorn.active:focus,
.show > .btn-matterhorn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(104, 104, 104, 0.5);
}

.btn-matterhorn:disabled, .btn-matterhorn.disabled {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-gray {
  color: #000000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-gray:hover {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-check:focus + .btn-gray, .btn-gray:focus {
  color: #000000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(210, 210, 210, 0.5);
}

.btn-check:checked + .btn-gray,
.btn-check:active + .btn-gray, .btn-gray:active, .btn-gray.active,
.show > .btn-gray.dropdown-toggle {
  color: #000000;
  background-color: white;
  border-color: white;
}

.btn-check:checked + .btn-gray:focus,
.btn-check:active + .btn-gray:focus, .btn-gray:active:focus, .btn-gray.active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(210, 210, 210, 0.5);
}

.btn-gray:disabled, .btn-gray.disabled {
  color: #000000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-outline-primary {
  color: #ffb300;
  border-color: #ffb300;
}

.btn-outline-primary:hover {
  color: #000000;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000000;
  background-color: #ffb300;
  border-color: #ffb300;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #ffb300;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #2b2b35;
  border-color: #2b2b35;
}

.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #2b2b35;
  border-color: #2b2b35;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 43, 53, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #2b2b35;
  border-color: #2b2b35;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 43, 53, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #2b2b35;
  background-color: transparent;
}

.btn-outline-default-color {
  color: #666666;
  border-color: #666666;
}

.btn-outline-default-color:hover {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.btn-check:focus + .btn-outline-default-color, .btn-outline-default-color:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5);
}

.btn-check:checked + .btn-outline-default-color,
.btn-check:active + .btn-outline-default-color, .btn-outline-default-color:active, .btn-outline-default-color.active, .btn-outline-default-color.dropdown-toggle.show {
  color: #ffffff;
  background-color: #666666;
  border-color: #666666;
}

.btn-check:checked + .btn-outline-default-color:focus,
.btn-check:active + .btn-outline-default-color:focus, .btn-outline-default-color:active:focus, .btn-outline-default-color.active:focus, .btn-outline-default-color.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5);
}

.btn-outline-default-color:disabled, .btn-outline-default-color.disabled {
  color: #666666;
  background-color: transparent;
}

.btn-outline-light {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-light:hover {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000000;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-outline-matterhorn {
  color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-outline-matterhorn:hover {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-check:focus + .btn-outline-matterhorn, .btn-outline-matterhorn:focus {
  box-shadow: 0 0 0 0.25rem rgba(77, 77, 77, 0.5);
}

.btn-check:checked + .btn-outline-matterhorn,
.btn-check:active + .btn-outline-matterhorn, .btn-outline-matterhorn:active, .btn-outline-matterhorn.active, .btn-outline-matterhorn.dropdown-toggle.show {
  color: #ffffff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.btn-check:checked + .btn-outline-matterhorn:focus,
.btn-check:active + .btn-outline-matterhorn:focus, .btn-outline-matterhorn:active:focus, .btn-outline-matterhorn.active:focus, .btn-outline-matterhorn.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(77, 77, 77, 0.5);
}

.btn-outline-matterhorn:disabled, .btn-outline-matterhorn.disabled {
  color: #4d4d4d;
  background-color: transparent;
}

.btn-outline-gray {
  color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-outline-gray:hover {
  color: #000000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-check:focus + .btn-outline-gray, .btn-outline-gray:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 247, 247, 0.5);
}

.btn-check:checked + .btn-outline-gray,
.btn-check:active + .btn-outline-gray, .btn-outline-gray:active, .btn-outline-gray.active, .btn-outline-gray.dropdown-toggle.show {
  color: #000000;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}

.btn-check:checked + .btn-outline-gray:focus,
.btn-check:active + .btn-outline-gray:focus, .btn-outline-gray:active:focus, .btn-outline-gray.active:focus, .btn-outline-gray.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 247, 247, 0.5);
}

.btn-outline-gray:disabled, .btn-outline-gray.disabled {
  color: #f7f7f7;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #666666;
  text-decoration: none;
}

.btn-link:hover {
  color: #ffb300;
}

.btn-link:disabled, .btn-link.disabled {
  color: #686868;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #666666;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #2e2e2e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #212121;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #ffb300;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #686868;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: #686868;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #2e2e2e;
}

.dropdown-menu-dark {
  color: #ececec;
  background-color: #2b2b35;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #ececec;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #ffffff;
  background-color: #ffb300;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #8c8c8c;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #ececec;
}

.dropdown-menu-dark .dropdown-header {
  color: #8c8c8c;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: #686868;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #ececec;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #dcd9d9 #dcd9d9 #ececec;
}

.nav-tabs .nav-link.disabled {
  color: #686868;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #666666;
  background-color: #ffffff;
  border-color: #ececec #ececec #ffffff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #ffb300;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.28902rem;
  padding-bottom: 0.28902rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #dcd9d9;
  border-radius: 0.25rem;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #686868;
  content: "/";
}

.breadcrumb-item.active {
  color: #686868;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #666666;
  background-color: #ffffff;
  border: 1px solid #ececec;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #ffb300;
  background-color: #dcd9d9;
  border-color: #ececec;
}

.page-link:focus {
  z-index: 3;
  color: #ffb300;
  background-color: #dcd9d9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #ffb300;
  border-color: #ffb300;
}

.page-item.disabled .page-link {
  color: #686868;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #ececec;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #855d00;
  background-color: #fff0cc;
  border-color: #ffeab8;
}

.alert-primary .alert-link {
  color: #523900;
}

.alert-secondary {
  color: #16161c;
  background-color: #d5d5d7;
  border-color: #c4c4c6;
}

.alert-secondary .alert-link {
  color: black;
}

.alert-default-color {
  color: #353535;
  background-color: #e0e0e0;
  border-color: #d4d4d4;
}

.alert-default-color .alert-link {
  color: #1c1c1c;
}

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-light .alert-link {
  color: #6c6c6c;
}

.alert-matterhorn {
  color: #282828;
  background-color: #dbdbdb;
  border-color: #cdcdcd;
}

.alert-matterhorn .alert-link {
  color: #0f0f0f;
}

.alert-gray {
  color: gray;
  background-color: #fdfdfd;
  border-color: #fdfdfd;
}

.alert-gray .alert-link {
  color: #676767;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #dcd9d9;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #ffb300;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #666666;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #666666;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #666666;
  background-color: #dcd9d9;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #686868;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #ffb300;
  border-color: #ffb300;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #855d00;
  background-color: #ffeab8;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #855d00;
  background-color: #ffe29f;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #855d00;
  border-color: #855d00;
}

.list-group-item-secondary {
  color: #16161c;
  background-color: #c4c4c6;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #16161c;
  background-color: #b7b7b9;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #16161c;
  border-color: #16161c;
}

.list-group-item-default-color {
  color: #353535;
  background-color: #d4d4d4;
}

.list-group-item-default-color.list-group-item-action:hover, .list-group-item-default-color.list-group-item-action:focus {
  color: #353535;
  background-color: #c7c7c7;
}

.list-group-item-default-color.list-group-item-action.active {
  color: #ffffff;
  background-color: #353535;
  border-color: #353535;
}

.list-group-item-light {
  color: #858585;
  background-color: white;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-matterhorn {
  color: #282828;
  background-color: #cdcdcd;
}

.list-group-item-matterhorn.list-group-item-action:hover, .list-group-item-matterhorn.list-group-item-action:focus {
  color: #282828;
  background-color: silver;
}

.list-group-item-matterhorn.list-group-item-action.active {
  color: #ffffff;
  background-color: #282828;
  border-color: #282828;
}

.list-group-item-gray {
  color: gray;
  background-color: #fdfdfd;
}

.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: gray;
  background-color: #f0f0f0;
}

.list-group-item-gray.list-group-item-action.active {
  color: #ffffff;
  background-color: gray;
  border-color: gray;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") no-repeat center center/1em auto;
  background-clip: content-box;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(255, 179, 0, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #686868;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #ececec;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.929;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #ececec;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.929;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.929;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #000000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #666666;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 16 16'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 16 16'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators li {
  background-color: #000000;
}

.carousel-dark .carousel-caption {
  color: #000000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #ffb300;
}

.link-primary:hover, .link-primary:focus {
  color: #ffca4d;
}

.link-secondary {
  color: #2b2b35;
}

.link-secondary:hover, .link-secondary:focus {
  color: #09090b;
}

.link-default-color {
  color: #666666;
}

.link-default-color:hover, .link-default-color:focus {
  color: #404040;
}

.link-light {
  color: #ffffff;
}

.link-light:hover, .link-light:focus {
  color: white;
}

.link-matterhorn {
  color: #4d4d4d;
}

.link-matterhorn:hover, .link-matterhorn:focus {
  color: #272727;
}

.link-gray {
  color: #f7f7f7;
}

.link-gray:hover, .link-gray:focus {
  color: white;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.left-0 {
  left: 0 !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

.right-0 {
  right: 0 !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translateX(-50%) translateY(-50%) !important;
}

.border {
  border: 1px solid #ececec !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #ececec !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right {
  border-right: 1px solid #ececec !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #ececec !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 1px solid #ececec !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ffb300 !important;
}

.border-secondary {
  border-color: #2b2b35 !important;
}

.border-default-color {
  border-color: #666666 !important;
}

.border-light {
  border-color: #ffffff !important;
}

.border-matterhorn {
  border-color: #4d4d4d !important;
}

.border-gray {
  border-color: #f7f7f7 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.m-3 {
  margin: 0.9375rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.625rem !important;
}

.m-6 {
  margin: 1.875rem !important;
}

.m-7 {
  margin: 2.1875rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.m-9 {
  margin: 2.8125rem !important;
}

.m-10 {
  margin: 3.125rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important;
}

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-3 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important;
}

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-5 {
  margin-right: 1.625rem !important;
  margin-left: 1.625rem !important;
}

.mx-6 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-7 {
  margin-right: 2.1875rem !important;
  margin-left: 2.1875rem !important;
}

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-9 {
  margin-right: 2.8125rem !important;
  margin-left: 2.8125rem !important;
}

.mx-10 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-5 {
  margin-top: 1.625rem !important;
  margin-bottom: 1.625rem !important;
}

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.mt-3 {
  margin-top: 0.9375rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.625rem !important;
}

.mt-6 {
  margin-top: 1.875rem !important;
}

.mt-7 {
  margin-top: 2.1875rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.mt-9 {
  margin-top: 2.8125rem !important;
}

.mt-10 {
  margin-top: 3.125rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.3125rem !important;
}

.mr-2 {
  margin-right: 0.625rem !important;
}

.mr-3 {
  margin-right: 0.9375rem !important;
}

.mr-4 {
  margin-right: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.625rem !important;
}

.mr-6 {
  margin-right: 1.875rem !important;
}

.mr-7 {
  margin-right: 2.1875rem !important;
}

.mr-8 {
  margin-right: 2.5rem !important;
}

.mr-9 {
  margin-right: 2.8125rem !important;
}

.mr-10 {
  margin-right: 3.125rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 0.9375rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.625rem !important;
}

.mb-6 {
  margin-bottom: 1.875rem !important;
}

.mb-7 {
  margin-bottom: 2.1875rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 2.8125rem !important;
}

.mb-10 {
  margin-bottom: 3.125rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.3125rem !important;
}

.ml-2 {
  margin-left: 0.625rem !important;
}

.ml-3 {
  margin-left: 0.9375rem !important;
}

.ml-4 {
  margin-left: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.625rem !important;
}

.ml-6 {
  margin-left: 1.875rem !important;
}

.ml-7 {
  margin-left: 2.1875rem !important;
}

.ml-8 {
  margin-left: 2.5rem !important;
}

.ml-9 {
  margin-left: 2.8125rem !important;
}

.ml-10 {
  margin-left: 3.125rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.m-n3 {
  margin: -0.9375rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.m-n5 {
  margin: -1.625rem !important;
}

.m-n6 {
  margin: -1.875rem !important;
}

.m-n7 {
  margin: -2.1875rem !important;
}

.m-n8 {
  margin: -2.5rem !important;
}

.m-n9 {
  margin: -2.8125rem !important;
}

.m-n10 {
  margin: -3.125rem !important;
}

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important;
}

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n3 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important;
}

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n5 {
  margin-right: -1.625rem !important;
  margin-left: -1.625rem !important;
}

.mx-n6 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n7 {
  margin-right: -2.1875rem !important;
  margin-left: -2.1875rem !important;
}

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n9 {
  margin-right: -2.8125rem !important;
  margin-left: -2.8125rem !important;
}

.mx-n10 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important;
}

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important;
}

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n3 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important;
}

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n5 {
  margin-top: -1.625rem !important;
  margin-bottom: -1.625rem !important;
}

.my-n6 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n7 {
  margin-top: -2.1875rem !important;
  margin-bottom: -2.1875rem !important;
}

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n9 {
  margin-top: -2.8125rem !important;
  margin-bottom: -2.8125rem !important;
}

.my-n10 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.mt-n1 {
  margin-top: -0.3125rem !important;
}

.mt-n2 {
  margin-top: -0.625rem !important;
}

.mt-n3 {
  margin-top: -0.9375rem !important;
}

.mt-n4 {
  margin-top: -1.25rem !important;
}

.mt-n5 {
  margin-top: -1.625rem !important;
}

.mt-n6 {
  margin-top: -1.875rem !important;
}

.mt-n7 {
  margin-top: -2.1875rem !important;
}

.mt-n8 {
  margin-top: -2.5rem !important;
}

.mt-n9 {
  margin-top: -2.8125rem !important;
}

.mt-n10 {
  margin-top: -3.125rem !important;
}

.mr-n1 {
  margin-right: -0.3125rem !important;
}

.mr-n2 {
  margin-right: -0.625rem !important;
}

.mr-n3 {
  margin-right: -0.9375rem !important;
}

.mr-n4 {
  margin-right: -1.25rem !important;
}

.mr-n5 {
  margin-right: -1.625rem !important;
}

.mr-n6 {
  margin-right: -1.875rem !important;
}

.mr-n7 {
  margin-right: -2.1875rem !important;
}

.mr-n8 {
  margin-right: -2.5rem !important;
}

.mr-n9 {
  margin-right: -2.8125rem !important;
}

.mr-n10 {
  margin-right: -3.125rem !important;
}

.mb-n1 {
  margin-bottom: -0.3125rem !important;
}

.mb-n2 {
  margin-bottom: -0.625rem !important;
}

.mb-n3 {
  margin-bottom: -0.9375rem !important;
}

.mb-n4 {
  margin-bottom: -1.25rem !important;
}

.mb-n5 {
  margin-bottom: -1.625rem !important;
}

.mb-n6 {
  margin-bottom: -1.875rem !important;
}

.mb-n7 {
  margin-bottom: -2.1875rem !important;
}

.mb-n8 {
  margin-bottom: -2.5rem !important;
}

.mb-n9 {
  margin-bottom: -2.8125rem !important;
}

.mb-n10 {
  margin-bottom: -3.125rem !important;
}

.ml-n1 {
  margin-left: -0.3125rem !important;
}

.ml-n2 {
  margin-left: -0.625rem !important;
}

.ml-n3 {
  margin-left: -0.9375rem !important;
}

.ml-n4 {
  margin-left: -1.25rem !important;
}

.ml-n5 {
  margin-left: -1.625rem !important;
}

.ml-n6 {
  margin-left: -1.875rem !important;
}

.ml-n7 {
  margin-left: -2.1875rem !important;
}

.ml-n8 {
  margin-left: -2.5rem !important;
}

.ml-n9 {
  margin-left: -2.8125rem !important;
}

.ml-n10 {
  margin-left: -3.125rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.p-3 {
  padding: 0.9375rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.625rem !important;
}

.p-6 {
  padding: 1.875rem !important;
}

.p-7 {
  padding: 2.1875rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.p-9 {
  padding: 2.8125rem !important;
}

.p-10 {
  padding: 3.125rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important;
}

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-3 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important;
}

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-5 {
  padding-right: 1.625rem !important;
  padding-left: 1.625rem !important;
}

.px-6 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-7 {
  padding-right: 2.1875rem !important;
  padding-left: 2.1875rem !important;
}

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-9 {
  padding-right: 2.8125rem !important;
  padding-left: 2.8125rem !important;
}

.px-10 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-5 {
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.pt-3 {
  padding-top: 0.9375rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.625rem !important;
}

.pt-6 {
  padding-top: 1.875rem !important;
}

.pt-7 {
  padding-top: 2.1875rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 2.8125rem !important;
}

.pt-10 {
  padding-top: 3.125rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.3125rem !important;
}

.pr-2 {
  padding-right: 0.625rem !important;
}

.pr-3 {
  padding-right: 0.9375rem !important;
}

.pr-4 {
  padding-right: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.625rem !important;
}

.pr-6 {
  padding-right: 1.875rem !important;
}

.pr-7 {
  padding-right: 2.1875rem !important;
}

.pr-8 {
  padding-right: 2.5rem !important;
}

.pr-9 {
  padding-right: 2.8125rem !important;
}

.pr-10 {
  padding-right: 3.125rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 0.9375rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.625rem !important;
}

.pb-6 {
  padding-bottom: 1.875rem !important;
}

.pb-7 {
  padding-bottom: 2.1875rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 2.8125rem !important;
}

.pb-10 {
  padding-bottom: 3.125rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.3125rem !important;
}

.pl-2 {
  padding-left: 0.625rem !important;
}

.pl-3 {
  padding-left: 0.9375rem !important;
}

.pl-4 {
  padding-left: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.625rem !important;
}

.pl-6 {
  padding-left: 1.875rem !important;
}

.pl-7 {
  padding-left: 2.1875rem !important;
}

.pl-8 {
  padding-left: 2.5rem !important;
}

.pl-9 {
  padding-left: 2.8125rem !important;
}

.pl-10 {
  padding-left: 3.125rem !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #ffb300 !important;
}

.text-secondary {
  color: #2b2b35 !important;
}

.text-default-color {
  color: #666666 !important;
}

.text-light {
  color: #ffffff !important;
}

.text-matterhorn {
  color: #4d4d4d !important;
}

.text-gray {
  color: #f7f7f7 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-body {
  color: #666666 !important;
}

.text-muted {
  color: #686868 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.929 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #ffb300 !important;
}

.bg-secondary {
  background-color: #2b2b35 !important;
}

.bg-default-color {
  background-color: #666666 !important;
}

.bg-light {
  background-color: #ffffff !important;
}

.bg-matterhorn {
  background-color: #4d4d4d !important;
}

.bg-gray {
  background-color: #f7f7f7 !important;
}

.bg-body {
  background-color: #ffffff !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 0.9375rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.625rem !important;
  }
  .m-sm-6 {
    margin: 1.875rem !important;
  }
  .m-sm-7 {
    margin: 2.1875rem !important;
  }
  .m-sm-8 {
    margin: 2.5rem !important;
  }
  .m-sm-9 {
    margin: 2.8125rem !important;
  }
  .m-sm-10 {
    margin: 3.125rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-sm-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-sm-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-sm-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.625rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-sm-8 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-sm-10 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mr-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .mr-sm-2 {
    margin-right: 0.625rem !important;
  }
  .mr-sm-3 {
    margin-right: 0.9375rem !important;
  }
  .mr-sm-4 {
    margin-right: 1.25rem !important;
  }
  .mr-sm-5 {
    margin-right: 1.625rem !important;
  }
  .mr-sm-6 {
    margin-right: 1.875rem !important;
  }
  .mr-sm-7 {
    margin-right: 2.1875rem !important;
  }
  .mr-sm-8 {
    margin-right: 2.5rem !important;
  }
  .mr-sm-9 {
    margin-right: 2.8125rem !important;
  }
  .mr-sm-10 {
    margin-right: 3.125rem !important;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .ml-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .ml-sm-2 {
    margin-left: 0.625rem !important;
  }
  .ml-sm-3 {
    margin-left: 0.9375rem !important;
  }
  .ml-sm-4 {
    margin-left: 1.25rem !important;
  }
  .ml-sm-5 {
    margin-left: 1.625rem !important;
  }
  .ml-sm-6 {
    margin-left: 1.875rem !important;
  }
  .ml-sm-7 {
    margin-left: 2.1875rem !important;
  }
  .ml-sm-8 {
    margin-left: 2.5rem !important;
  }
  .ml-sm-9 {
    margin-left: 2.8125rem !important;
  }
  .ml-sm-10 {
    margin-left: 3.125rem !important;
  }
  .ml-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.3125rem !important;
  }
  .m-sm-n2 {
    margin: -0.625rem !important;
  }
  .m-sm-n3 {
    margin: -0.9375rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.625rem !important;
  }
  .m-sm-n6 {
    margin: -1.875rem !important;
  }
  .m-sm-n7 {
    margin: -2.1875rem !important;
  }
  .m-sm-n8 {
    margin: -2.5rem !important;
  }
  .m-sm-n9 {
    margin: -2.8125rem !important;
  }
  .m-sm-n10 {
    margin: -3.125rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-sm-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-sm-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-sm-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3.125rem !important;
  }
  .mr-sm-n1 {
    margin-right: -0.3125rem !important;
  }
  .mr-sm-n2 {
    margin-right: -0.625rem !important;
  }
  .mr-sm-n3 {
    margin-right: -0.9375rem !important;
  }
  .mr-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .mr-sm-n5 {
    margin-right: -1.625rem !important;
  }
  .mr-sm-n6 {
    margin-right: -1.875rem !important;
  }
  .mr-sm-n7 {
    margin-right: -2.1875rem !important;
  }
  .mr-sm-n8 {
    margin-right: -2.5rem !important;
  }
  .mr-sm-n9 {
    margin-right: -2.8125rem !important;
  }
  .mr-sm-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ml-sm-n1 {
    margin-left: -0.3125rem !important;
  }
  .ml-sm-n2 {
    margin-left: -0.625rem !important;
  }
  .ml-sm-n3 {
    margin-left: -0.9375rem !important;
  }
  .ml-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .ml-sm-n5 {
    margin-left: -1.625rem !important;
  }
  .ml-sm-n6 {
    margin-left: -1.875rem !important;
  }
  .ml-sm-n7 {
    margin-left: -2.1875rem !important;
  }
  .ml-sm-n8 {
    margin-left: -2.5rem !important;
  }
  .ml-sm-n9 {
    margin-left: -2.8125rem !important;
  }
  .ml-sm-n10 {
    margin-left: -3.125rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 0.9375rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.625rem !important;
  }
  .p-sm-6 {
    padding: 1.875rem !important;
  }
  .p-sm-7 {
    padding: 2.1875rem !important;
  }
  .p-sm-8 {
    padding: 2.5rem !important;
  }
  .p-sm-9 {
    padding: 2.8125rem !important;
  }
  .p-sm-10 {
    padding: 3.125rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-sm-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-sm-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-sm-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-sm-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.625rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-sm-8 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-sm-10 {
    padding-top: 3.125rem !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pr-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pr-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pr-sm-3 {
    padding-right: 0.9375rem !important;
  }
  .pr-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pr-sm-5 {
    padding-right: 1.625rem !important;
  }
  .pr-sm-6 {
    padding-right: 1.875rem !important;
  }
  .pr-sm-7 {
    padding-right: 2.1875rem !important;
  }
  .pr-sm-8 {
    padding-right: 2.5rem !important;
  }
  .pr-sm-9 {
    padding-right: 2.8125rem !important;
  }
  .pr-sm-10 {
    padding-right: 3.125rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3.125rem !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pl-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .pl-sm-2 {
    padding-left: 0.625rem !important;
  }
  .pl-sm-3 {
    padding-left: 0.9375rem !important;
  }
  .pl-sm-4 {
    padding-left: 1.25rem !important;
  }
  .pl-sm-5 {
    padding-left: 1.625rem !important;
  }
  .pl-sm-6 {
    padding-left: 1.875rem !important;
  }
  .pl-sm-7 {
    padding-left: 2.1875rem !important;
  }
  .pl-sm-8 {
    padding-left: 2.5rem !important;
  }
  .pl-sm-9 {
    padding-left: 2.8125rem !important;
  }
  .pl-sm-10 {
    padding-left: 3.125rem !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .m-md-3 {
    margin: 0.9375rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.625rem !important;
  }
  .m-md-6 {
    margin: 1.875rem !important;
  }
  .m-md-7 {
    margin: 2.1875rem !important;
  }
  .m-md-8 {
    margin: 2.5rem !important;
  }
  .m-md-9 {
    margin: 2.8125rem !important;
  }
  .m-md-10 {
    margin: 3.125rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-md-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-md-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-md-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-md-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-md-2 {
    margin-top: 0.625rem !important;
  }
  .mt-md-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-md-4 {
    margin-top: 1.25rem !important;
  }
  .mt-md-5 {
    margin-top: 1.625rem !important;
  }
  .mt-md-6 {
    margin-top: 1.875rem !important;
  }
  .mt-md-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-md-8 {
    margin-top: 2.5rem !important;
  }
  .mt-md-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-md-10 {
    margin-top: 3.125rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mr-md-1 {
    margin-right: 0.3125rem !important;
  }
  .mr-md-2 {
    margin-right: 0.625rem !important;
  }
  .mr-md-3 {
    margin-right: 0.9375rem !important;
  }
  .mr-md-4 {
    margin-right: 1.25rem !important;
  }
  .mr-md-5 {
    margin-right: 1.625rem !important;
  }
  .mr-md-6 {
    margin-right: 1.875rem !important;
  }
  .mr-md-7 {
    margin-right: 2.1875rem !important;
  }
  .mr-md-8 {
    margin-right: 2.5rem !important;
  }
  .mr-md-9 {
    margin-right: 2.8125rem !important;
  }
  .mr-md-10 {
    margin-right: 3.125rem !important;
  }
  .mr-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .ml-md-1 {
    margin-left: 0.3125rem !important;
  }
  .ml-md-2 {
    margin-left: 0.625rem !important;
  }
  .ml-md-3 {
    margin-left: 0.9375rem !important;
  }
  .ml-md-4 {
    margin-left: 1.25rem !important;
  }
  .ml-md-5 {
    margin-left: 1.625rem !important;
  }
  .ml-md-6 {
    margin-left: 1.875rem !important;
  }
  .ml-md-7 {
    margin-left: 2.1875rem !important;
  }
  .ml-md-8 {
    margin-left: 2.5rem !important;
  }
  .ml-md-9 {
    margin-left: 2.8125rem !important;
  }
  .ml-md-10 {
    margin-left: 3.125rem !important;
  }
  .ml-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.3125rem !important;
  }
  .m-md-n2 {
    margin: -0.625rem !important;
  }
  .m-md-n3 {
    margin: -0.9375rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.625rem !important;
  }
  .m-md-n6 {
    margin: -1.875rem !important;
  }
  .m-md-n7 {
    margin: -2.1875rem !important;
  }
  .m-md-n8 {
    margin: -2.5rem !important;
  }
  .m-md-n9 {
    margin: -2.8125rem !important;
  }
  .m-md-n10 {
    margin: -3.125rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-md-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-md-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-md-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-md-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-md-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-md-n10 {
    margin-top: -3.125rem !important;
  }
  .mr-md-n1 {
    margin-right: -0.3125rem !important;
  }
  .mr-md-n2 {
    margin-right: -0.625rem !important;
  }
  .mr-md-n3 {
    margin-right: -0.9375rem !important;
  }
  .mr-md-n4 {
    margin-right: -1.25rem !important;
  }
  .mr-md-n5 {
    margin-right: -1.625rem !important;
  }
  .mr-md-n6 {
    margin-right: -1.875rem !important;
  }
  .mr-md-n7 {
    margin-right: -2.1875rem !important;
  }
  .mr-md-n8 {
    margin-right: -2.5rem !important;
  }
  .mr-md-n9 {
    margin-right: -2.8125rem !important;
  }
  .mr-md-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ml-md-n1 {
    margin-left: -0.3125rem !important;
  }
  .ml-md-n2 {
    margin-left: -0.625rem !important;
  }
  .ml-md-n3 {
    margin-left: -0.9375rem !important;
  }
  .ml-md-n4 {
    margin-left: -1.25rem !important;
  }
  .ml-md-n5 {
    margin-left: -1.625rem !important;
  }
  .ml-md-n6 {
    margin-left: -1.875rem !important;
  }
  .ml-md-n7 {
    margin-left: -2.1875rem !important;
  }
  .ml-md-n8 {
    margin-left: -2.5rem !important;
  }
  .ml-md-n9 {
    margin-left: -2.8125rem !important;
  }
  .ml-md-n10 {
    margin-left: -3.125rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .p-md-3 {
    padding: 0.9375rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.625rem !important;
  }
  .p-md-6 {
    padding: 1.875rem !important;
  }
  .p-md-7 {
    padding: 2.1875rem !important;
  }
  .p-md-8 {
    padding: 2.5rem !important;
  }
  .p-md-9 {
    padding: 2.8125rem !important;
  }
  .p-md-10 {
    padding: 3.125rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-md-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-md-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-md-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-md-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-md-2 {
    padding-top: 0.625rem !important;
  }
  .pt-md-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-md-4 {
    padding-top: 1.25rem !important;
  }
  .pt-md-5 {
    padding-top: 1.625rem !important;
  }
  .pt-md-6 {
    padding-top: 1.875rem !important;
  }
  .pt-md-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-md-8 {
    padding-top: 2.5rem !important;
  }
  .pt-md-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-md-10 {
    padding-top: 3.125rem !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pr-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pr-md-2 {
    padding-right: 0.625rem !important;
  }
  .pr-md-3 {
    padding-right: 0.9375rem !important;
  }
  .pr-md-4 {
    padding-right: 1.25rem !important;
  }
  .pr-md-5 {
    padding-right: 1.625rem !important;
  }
  .pr-md-6 {
    padding-right: 1.875rem !important;
  }
  .pr-md-7 {
    padding-right: 2.1875rem !important;
  }
  .pr-md-8 {
    padding-right: 2.5rem !important;
  }
  .pr-md-9 {
    padding-right: 2.8125rem !important;
  }
  .pr-md-10 {
    padding-right: 3.125rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3.125rem !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pl-md-1 {
    padding-left: 0.3125rem !important;
  }
  .pl-md-2 {
    padding-left: 0.625rem !important;
  }
  .pl-md-3 {
    padding-left: 0.9375rem !important;
  }
  .pl-md-4 {
    padding-left: 1.25rem !important;
  }
  .pl-md-5 {
    padding-left: 1.625rem !important;
  }
  .pl-md-6 {
    padding-left: 1.875rem !important;
  }
  .pl-md-7 {
    padding-left: 2.1875rem !important;
  }
  .pl-md-8 {
    padding-left: 2.5rem !important;
  }
  .pl-md-9 {
    padding-left: 2.8125rem !important;
  }
  .pl-md-10 {
    padding-left: 3.125rem !important;
  }
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 0.9375rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.625rem !important;
  }
  .m-lg-6 {
    margin: 1.875rem !important;
  }
  .m-lg-7 {
    margin: 2.1875rem !important;
  }
  .m-lg-8 {
    margin: 2.5rem !important;
  }
  .m-lg-9 {
    margin: 2.8125rem !important;
  }
  .m-lg-10 {
    margin: 3.125rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-lg-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-lg-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-lg-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.625rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-lg-8 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-lg-10 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mr-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .mr-lg-2 {
    margin-right: 0.625rem !important;
  }
  .mr-lg-3 {
    margin-right: 0.9375rem !important;
  }
  .mr-lg-4 {
    margin-right: 1.25rem !important;
  }
  .mr-lg-5 {
    margin-right: 1.625rem !important;
  }
  .mr-lg-6 {
    margin-right: 1.875rem !important;
  }
  .mr-lg-7 {
    margin-right: 2.1875rem !important;
  }
  .mr-lg-8 {
    margin-right: 2.5rem !important;
  }
  .mr-lg-9 {
    margin-right: 2.8125rem !important;
  }
  .mr-lg-10 {
    margin-right: 3.125rem !important;
  }
  .mr-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .ml-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .ml-lg-2 {
    margin-left: 0.625rem !important;
  }
  .ml-lg-3 {
    margin-left: 0.9375rem !important;
  }
  .ml-lg-4 {
    margin-left: 1.25rem !important;
  }
  .ml-lg-5 {
    margin-left: 1.625rem !important;
  }
  .ml-lg-6 {
    margin-left: 1.875rem !important;
  }
  .ml-lg-7 {
    margin-left: 2.1875rem !important;
  }
  .ml-lg-8 {
    margin-left: 2.5rem !important;
  }
  .ml-lg-9 {
    margin-left: 2.8125rem !important;
  }
  .ml-lg-10 {
    margin-left: 3.125rem !important;
  }
  .ml-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.3125rem !important;
  }
  .m-lg-n2 {
    margin: -0.625rem !important;
  }
  .m-lg-n3 {
    margin: -0.9375rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.625rem !important;
  }
  .m-lg-n6 {
    margin: -1.875rem !important;
  }
  .m-lg-n7 {
    margin: -2.1875rem !important;
  }
  .m-lg-n8 {
    margin: -2.5rem !important;
  }
  .m-lg-n9 {
    margin: -2.8125rem !important;
  }
  .m-lg-n10 {
    margin: -3.125rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-lg-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-lg-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-lg-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3.125rem !important;
  }
  .mr-lg-n1 {
    margin-right: -0.3125rem !important;
  }
  .mr-lg-n2 {
    margin-right: -0.625rem !important;
  }
  .mr-lg-n3 {
    margin-right: -0.9375rem !important;
  }
  .mr-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .mr-lg-n5 {
    margin-right: -1.625rem !important;
  }
  .mr-lg-n6 {
    margin-right: -1.875rem !important;
  }
  .mr-lg-n7 {
    margin-right: -2.1875rem !important;
  }
  .mr-lg-n8 {
    margin-right: -2.5rem !important;
  }
  .mr-lg-n9 {
    margin-right: -2.8125rem !important;
  }
  .mr-lg-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ml-lg-n1 {
    margin-left: -0.3125rem !important;
  }
  .ml-lg-n2 {
    margin-left: -0.625rem !important;
  }
  .ml-lg-n3 {
    margin-left: -0.9375rem !important;
  }
  .ml-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .ml-lg-n5 {
    margin-left: -1.625rem !important;
  }
  .ml-lg-n6 {
    margin-left: -1.875rem !important;
  }
  .ml-lg-n7 {
    margin-left: -2.1875rem !important;
  }
  .ml-lg-n8 {
    margin-left: -2.5rem !important;
  }
  .ml-lg-n9 {
    margin-left: -2.8125rem !important;
  }
  .ml-lg-n10 {
    margin-left: -3.125rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 0.9375rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.625rem !important;
  }
  .p-lg-6 {
    padding: 1.875rem !important;
  }
  .p-lg-7 {
    padding: 2.1875rem !important;
  }
  .p-lg-8 {
    padding: 2.5rem !important;
  }
  .p-lg-9 {
    padding: 2.8125rem !important;
  }
  .p-lg-10 {
    padding: 3.125rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-lg-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-lg-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-lg-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-lg-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.625rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-lg-8 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-lg-10 {
    padding-top: 3.125rem !important;
  }
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pr-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pr-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pr-lg-3 {
    padding-right: 0.9375rem !important;
  }
  .pr-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pr-lg-5 {
    padding-right: 1.625rem !important;
  }
  .pr-lg-6 {
    padding-right: 1.875rem !important;
  }
  .pr-lg-7 {
    padding-right: 2.1875rem !important;
  }
  .pr-lg-8 {
    padding-right: 2.5rem !important;
  }
  .pr-lg-9 {
    padding-right: 2.8125rem !important;
  }
  .pr-lg-10 {
    padding-right: 3.125rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3.125rem !important;
  }
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .pl-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .pl-lg-2 {
    padding-left: 0.625rem !important;
  }
  .pl-lg-3 {
    padding-left: 0.9375rem !important;
  }
  .pl-lg-4 {
    padding-left: 1.25rem !important;
  }
  .pl-lg-5 {
    padding-left: 1.625rem !important;
  }
  .pl-lg-6 {
    padding-left: 1.875rem !important;
  }
  .pl-lg-7 {
    padding-left: 2.1875rem !important;
  }
  .pl-lg-8 {
    padding-left: 2.5rem !important;
  }
  .pl-lg-9 {
    padding-left: 2.8125rem !important;
  }
  .pl-lg-10 {
    padding-left: 3.125rem !important;
  }
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 0.9375rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.625rem !important;
  }
  .m-xl-6 {
    margin: 1.875rem !important;
  }
  .m-xl-7 {
    margin: 2.1875rem !important;
  }
  .m-xl-8 {
    margin: 2.5rem !important;
  }
  .m-xl-9 {
    margin: 2.8125rem !important;
  }
  .m-xl-10 {
    margin: 3.125rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-0 {
    margin-right: 0 !important;
  }
  .mr-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .mr-xl-2 {
    margin-right: 0.625rem !important;
  }
  .mr-xl-3 {
    margin-right: 0.9375rem !important;
  }
  .mr-xl-4 {
    margin-right: 1.25rem !important;
  }
  .mr-xl-5 {
    margin-right: 1.625rem !important;
  }
  .mr-xl-6 {
    margin-right: 1.875rem !important;
  }
  .mr-xl-7 {
    margin-right: 2.1875rem !important;
  }
  .mr-xl-8 {
    margin-right: 2.5rem !important;
  }
  .mr-xl-9 {
    margin-right: 2.8125rem !important;
  }
  .mr-xl-10 {
    margin-right: 3.125rem !important;
  }
  .mr-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-0 {
    margin-left: 0 !important;
  }
  .ml-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .ml-xl-2 {
    margin-left: 0.625rem !important;
  }
  .ml-xl-3 {
    margin-left: 0.9375rem !important;
  }
  .ml-xl-4 {
    margin-left: 1.25rem !important;
  }
  .ml-xl-5 {
    margin-left: 1.625rem !important;
  }
  .ml-xl-6 {
    margin-left: 1.875rem !important;
  }
  .ml-xl-7 {
    margin-left: 2.1875rem !important;
  }
  .ml-xl-8 {
    margin-left: 2.5rem !important;
  }
  .ml-xl-9 {
    margin-left: 2.8125rem !important;
  }
  .ml-xl-10 {
    margin-left: 3.125rem !important;
  }
  .ml-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xl-n2 {
    margin: -0.625rem !important;
  }
  .m-xl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.625rem !important;
  }
  .m-xl-n6 {
    margin: -1.875rem !important;
  }
  .m-xl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xl-n8 {
    margin: -2.5rem !important;
  }
  .m-xl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3.125rem !important;
  }
  .mr-xl-n1 {
    margin-right: -0.3125rem !important;
  }
  .mr-xl-n2 {
    margin-right: -0.625rem !important;
  }
  .mr-xl-n3 {
    margin-right: -0.9375rem !important;
  }
  .mr-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .mr-xl-n5 {
    margin-right: -1.625rem !important;
  }
  .mr-xl-n6 {
    margin-right: -1.875rem !important;
  }
  .mr-xl-n7 {
    margin-right: -2.1875rem !important;
  }
  .mr-xl-n8 {
    margin-right: -2.5rem !important;
  }
  .mr-xl-n9 {
    margin-right: -2.8125rem !important;
  }
  .mr-xl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ml-xl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ml-xl-n2 {
    margin-left: -0.625rem !important;
  }
  .ml-xl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ml-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .ml-xl-n5 {
    margin-left: -1.625rem !important;
  }
  .ml-xl-n6 {
    margin-left: -1.875rem !important;
  }
  .ml-xl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ml-xl-n8 {
    margin-left: -2.5rem !important;
  }
  .ml-xl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ml-xl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 0.9375rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.625rem !important;
  }
  .p-xl-6 {
    padding: 1.875rem !important;
  }
  .p-xl-7 {
    padding: 2.1875rem !important;
  }
  .p-xl-8 {
    padding: 2.5rem !important;
  }
  .p-xl-9 {
    padding: 2.8125rem !important;
  }
  .p-xl-10 {
    padding: 3.125rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xl-10 {
    padding-top: 3.125rem !important;
  }
  .pr-xl-0 {
    padding-right: 0 !important;
  }
  .pr-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pr-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pr-xl-3 {
    padding-right: 0.9375rem !important;
  }
  .pr-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pr-xl-5 {
    padding-right: 1.625rem !important;
  }
  .pr-xl-6 {
    padding-right: 1.875rem !important;
  }
  .pr-xl-7 {
    padding-right: 2.1875rem !important;
  }
  .pr-xl-8 {
    padding-right: 2.5rem !important;
  }
  .pr-xl-9 {
    padding-right: 2.8125rem !important;
  }
  .pr-xl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3.125rem !important;
  }
  .pl-xl-0 {
    padding-left: 0 !important;
  }
  .pl-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .pl-xl-2 {
    padding-left: 0.625rem !important;
  }
  .pl-xl-3 {
    padding-left: 0.9375rem !important;
  }
  .pl-xl-4 {
    padding-left: 1.25rem !important;
  }
  .pl-xl-5 {
    padding-left: 1.625rem !important;
  }
  .pl-xl-6 {
    padding-left: 1.875rem !important;
  }
  .pl-xl-7 {
    padding-left: 2.1875rem !important;
  }
  .pl-xl-8 {
    padding-left: 2.5rem !important;
  }
  .pl-xl-9 {
    padding-left: 2.8125rem !important;
  }
  .pl-xl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3125rem !important;
  }
  .m-xxl-2 {
    margin: 0.625rem !important;
  }
  .m-xxl-3 {
    margin: 0.9375rem !important;
  }
  .m-xxl-4 {
    margin: 1.25rem !important;
  }
  .m-xxl-5 {
    margin: 1.625rem !important;
  }
  .m-xxl-6 {
    margin: 1.875rem !important;
  }
  .m-xxl-7 {
    margin: 2.1875rem !important;
  }
  .m-xxl-8 {
    margin: 2.5rem !important;
  }
  .m-xxl-9 {
    margin: 2.8125rem !important;
  }
  .m-xxl-10 {
    margin: 3.125rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xxl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-0 {
    margin-right: 0 !important;
  }
  .mr-xxl-1 {
    margin-right: 0.3125rem !important;
  }
  .mr-xxl-2 {
    margin-right: 0.625rem !important;
  }
  .mr-xxl-3 {
    margin-right: 0.9375rem !important;
  }
  .mr-xxl-4 {
    margin-right: 1.25rem !important;
  }
  .mr-xxl-5 {
    margin-right: 1.625rem !important;
  }
  .mr-xxl-6 {
    margin-right: 1.875rem !important;
  }
  .mr-xxl-7 {
    margin-right: 2.1875rem !important;
  }
  .mr-xxl-8 {
    margin-right: 2.5rem !important;
  }
  .mr-xxl-9 {
    margin-right: 2.8125rem !important;
  }
  .mr-xxl-10 {
    margin-right: 3.125rem !important;
  }
  .mr-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-0 {
    margin-left: 0 !important;
  }
  .ml-xxl-1 {
    margin-left: 0.3125rem !important;
  }
  .ml-xxl-2 {
    margin-left: 0.625rem !important;
  }
  .ml-xxl-3 {
    margin-left: 0.9375rem !important;
  }
  .ml-xxl-4 {
    margin-left: 1.25rem !important;
  }
  .ml-xxl-5 {
    margin-left: 1.625rem !important;
  }
  .ml-xxl-6 {
    margin-left: 1.875rem !important;
  }
  .ml-xxl-7 {
    margin-left: 2.1875rem !important;
  }
  .ml-xxl-8 {
    margin-left: 2.5rem !important;
  }
  .ml-xxl-9 {
    margin-left: 2.8125rem !important;
  }
  .ml-xxl-10 {
    margin-left: 3.125rem !important;
  }
  .ml-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xxl-n2 {
    margin: -0.625rem !important;
  }
  .m-xxl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xxl-n4 {
    margin: -1.25rem !important;
  }
  .m-xxl-n5 {
    margin: -1.625rem !important;
  }
  .m-xxl-n6 {
    margin: -1.875rem !important;
  }
  .m-xxl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xxl-n8 {
    margin: -2.5rem !important;
  }
  .m-xxl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xxl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xxl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -3.125rem !important;
  }
  .mr-xxl-n1 {
    margin-right: -0.3125rem !important;
  }
  .mr-xxl-n2 {
    margin-right: -0.625rem !important;
  }
  .mr-xxl-n3 {
    margin-right: -0.9375rem !important;
  }
  .mr-xxl-n4 {
    margin-right: -1.25rem !important;
  }
  .mr-xxl-n5 {
    margin-right: -1.625rem !important;
  }
  .mr-xxl-n6 {
    margin-right: -1.875rem !important;
  }
  .mr-xxl-n7 {
    margin-right: -2.1875rem !important;
  }
  .mr-xxl-n8 {
    margin-right: -2.5rem !important;
  }
  .mr-xxl-n9 {
    margin-right: -2.8125rem !important;
  }
  .mr-xxl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ml-xxl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ml-xxl-n2 {
    margin-left: -0.625rem !important;
  }
  .ml-xxl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ml-xxl-n4 {
    margin-left: -1.25rem !important;
  }
  .ml-xxl-n5 {
    margin-left: -1.625rem !important;
  }
  .ml-xxl-n6 {
    margin-left: -1.875rem !important;
  }
  .ml-xxl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ml-xxl-n8 {
    margin-left: -2.5rem !important;
  }
  .ml-xxl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ml-xxl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3125rem !important;
  }
  .p-xxl-2 {
    padding: 0.625rem !important;
  }
  .p-xxl-3 {
    padding: 0.9375rem !important;
  }
  .p-xxl-4 {
    padding: 1.25rem !important;
  }
  .p-xxl-5 {
    padding: 1.625rem !important;
  }
  .p-xxl-6 {
    padding: 1.875rem !important;
  }
  .p-xxl-7 {
    padding: 2.1875rem !important;
  }
  .p-xxl-8 {
    padding: 2.5rem !important;
  }
  .p-xxl-9 {
    padding: 2.8125rem !important;
  }
  .p-xxl-10 {
    padding: 3.125rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xxl-10 {
    padding-top: 3.125rem !important;
  }
  .pr-xxl-0 {
    padding-right: 0 !important;
  }
  .pr-xxl-1 {
    padding-right: 0.3125rem !important;
  }
  .pr-xxl-2 {
    padding-right: 0.625rem !important;
  }
  .pr-xxl-3 {
    padding-right: 0.9375rem !important;
  }
  .pr-xxl-4 {
    padding-right: 1.25rem !important;
  }
  .pr-xxl-5 {
    padding-right: 1.625rem !important;
  }
  .pr-xxl-6 {
    padding-right: 1.875rem !important;
  }
  .pr-xxl-7 {
    padding-right: 2.1875rem !important;
  }
  .pr-xxl-8 {
    padding-right: 2.5rem !important;
  }
  .pr-xxl-9 {
    padding-right: 2.8125rem !important;
  }
  .pr-xxl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 3.125rem !important;
  }
  .pl-xxl-0 {
    padding-left: 0 !important;
  }
  .pl-xxl-1 {
    padding-left: 0.3125rem !important;
  }
  .pl-xxl-2 {
    padding-left: 0.625rem !important;
  }
  .pl-xxl-3 {
    padding-left: 0.9375rem !important;
  }
  .pl-xxl-4 {
    padding-left: 1.25rem !important;
  }
  .pl-xxl-5 {
    padding-left: 1.625rem !important;
  }
  .pl-xxl-6 {
    padding-left: 1.875rem !important;
  }
  .pl-xxl-7 {
    padding-left: 2.1875rem !important;
  }
  .pl-xxl-8 {
    padding-left: 2.5rem !important;
  }
  .pl-xxl-9 {
    padding-left: 2.8125rem !important;
  }
  .pl-xxl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  visibility: visible;
  color: "";
}

body[dir="rtl"] {
  text-align: right;
}

a,
button {
  transition: all 0.3s ease 0s;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

/* No Gutters */
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

/* Image Fit */
.fit-image {
  width: 100%;
  object-fit: cover;
}

/* Body Overlay */
.body-overlay {
  background-color: rgba(255, 255, 255, 0.8);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  cursor: crosshair;
}

/* Scroll Up */
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 25px;
  line-height: 50px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #666666;
  background-size: 200% auto;
  background-position: left center;
  transition: .3s;
  overflow: hidden;
}

.scroll-top:hover {
  background-color: #ffb300;
}

.scroll-top:hover i {
  color: #ffffff;
  transition: .3s;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    font-size: 20px;
  }
}

.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}

.scroll-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transition: .3s;
}

.scroll-top .arrow-top {
  transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
  transform: translate(-50%, 80px);
}

.scroll-top:hover {
  background-position: right center;
}

.scroll-top:hover .arrow-top {
  transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
  transform: translate(-50%, -50%);
}

/* ---Social Links --- */
.widget-social {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.widget-social a {
  font-size: 16px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background: #ececec;
  color: #000000;
  margin-right: 10px;
  border-radius: 50%;
}

.widget-social a:hover {
  background: #ffb300;
  color: #ffffff;
}

@media only screen and (max-width: 479px) {
  .widget-social a {
    margin-right: 5px;
  }
}

/* Section Style */
.section,
.section-fluid {
  float: left;
  width: 100%;
}

.section-fluid {
  padding-right: 160px;
  padding-left: 160px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .section-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .section-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

/* Section Spacing */
.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-margin {
  margin-top: 120px;
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-margin {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-margin {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.section-padding-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-margin-sm {
  margin-top: 60px;
  margin-bottom: 60px;
}

/* Section TItle */
.section-title {
  margin-bottom: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title {
    margin-bottom: 40px;
  }
}

.section-title .title {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.16;
  margin-bottom: 0;
  margin-top: -7px;
  color: #000000;
  display: flex;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .section-title .title {
    font-size: 26px;
  }
}

.section-title span {
  content: "";
  width: 100px;
  height: 2px;
  background: #ffb300;
  margin-top: 15px;
  margin-bottom: 15px;
}

.section-title .sub-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 1.4;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 479px) {
  .section-title .sub-title {
    font-size: 14px;
    line-height: 1.6;
  }
}

/* swiper default styles */
.swiper-pagination {
  position: static;
  display: block;
  line-height: 1;
  margin-top: 20px;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border: 2px solid #d8d8d8;
  border-radius: 100%;
  background: transparent;
  opacity: 1;
  position: relative;
  outline: none;
  margin-right: 20px;
  margin: 0 8px !important;
}

.swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.swiper-pagination .swiper-pagination-bullet:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.swiper-pagination .swiper-pagination-bullet:hover:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: #d8d8d8;
  border-radius: 100%;
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #d8d8d8;
}

.swiper-pagination.swiper-pagination-primary .swiper-pagination-bullet:hover:before {
  background: #ffb300;
}

.swiper-pagination.swiper-pagination-primary .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 2px solid #ffb300;
  background: #ffb300;
}

.swiper-nav-button {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.1s linear 2s, opacity 0.1s linear 2s;
  background-image: none;
  text-align: center;
  user-select: none;
  outline: none;
  width: 48px;
  height: 48px;
  font-size: 24px;
  color: #6d70a6;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  opacity: 0;
  visibility: hidden;
  transform: translate(0, -50%);
  margin: 0;
  top: 50%;
}

.swiper-nav-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  color: #ffffff;
  background: #ffffff;
}

.swiper-nav-button:hover {
  color: #ffffff;
}

.swiper-nav-button:hover::before {
  color: #ffffff;
  background: #000000;
}

.swiper-container {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.swiper-container:hover .swiper-nav-button {
  opacity: 1;
  visibility: visible;
}

.swiper-nav-button i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-nav-button i::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: '\f104';
}

.swiper-nav-prev i::before {
  content: '\f104';
}

.swiper-button-next i::before {
  content: '\f105';
}

/* Overlay */
.overlay {
  position: relative;
  display: block;
  overflow: hidden;
  transition: all 0.3s ease 0s;
}

.overlay:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  opacity: 0;
  z-index: 1;
  transition: all 0.3s ease 0s;
}

.overlay img {
  width: 100%;
  transition-duration: 700ms;
  transition: all 0.3s ease 0s;
}

.overlay:hover:after {
  opacity: .5;
}

.overlay:hover img {
  transform: scale(1.2) rotate(0.5deg);
  transition-duration: 700ms;
  transition: 0.4s;
}

/* Contact Map */
.contact-map {
  height: 500px;
  width: 100%;
  display: block;
}

/*----------------------------------------*/
/*  02. Header CSS
/*----------------------------------------*/
/* ======== 2.1 Header Top ========*/
.header-top {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px), only screen and (max-width: 575px) {
  .header-top {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-top {
    display: none;
  }
}

/*-- Links --*/
.header-top-links {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px), only screen and (max-width: 575px) {
  .header-top-links {
    justify-content: center;
    margin-bottom: 10px;
  }
}

.header-top-links li {
  color: #ffffff;
  padding: 0 30px;
  border-left: 1px solid #41414a;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-top-links li {
    padding: 0 12px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .header-top-links li {
    padding: 0 12px;
    font-size: 13px;
    border-left: 0px;
    margin-bottom: 5px;
  }
}

.header-top-links li:first-child {
  border-left: 0px;
  padding-left: 0px;
}

.header-top-links li a {
  color: #ffffff;
}

.header-top-links li a:hover {
  color: #ffb300;
}

.header-top-links li i {
  color: #ffb300;
  margin-right: 10px;
  font-size: 22px;
}

/*-- Header Top Action --*/
.header-top-action {
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px), only screen and (max-width: 575px) {
  .header-top-action {
    width: 290px;
    margin: 0 auto;
  }
}

.header-top-action .action-search {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border: 1px solid #41414a;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  padding-right: 35px;
  background: transparent;
  outline: 0;
  transition: all 0.3s ease 0s;
  width: 250px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px), only screen and (max-width: 575px) {
  .header-top-action .action-search {
    width: 100%;
  }
}

.header-top-action .action-search:focus {
  border: 1px solid #ffb300;
  transition: all 0.3s ease 0s;
}

.header-top-action .action-search::placeholder {
  color: #ffffff;
  opacity: 1;
}

.header-top-action .action-btn {
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  right: 0;
  color: #ffffff;
  font-size: 16px;
  border: none;
  padding-right: 15px;
  padding-left: 5px;
  background: transparent;
  transition: all 0.3s ease 0s;
}

.header-top-action .action-btn:hover {
  color: #ffb300;
  transition: all 0.3s ease 0s;
}

/* ======== 2.2 Header Bottom ========*/
.header-bottom {
  background: transparent;
}

.header-bottom.header-sticky {
  min-height: 100px;
}

@media only screen and (max-width: 479px) {
  .header-bottom.header-sticky {
    min-height: 80px;
  }
}

.header-bottom.header-sticky.sticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

/*-- Logo --*/
.header-logo a img {
  max-width: 100%;
}

/*-- Main Menu --*/
.main-menu {
  display: flex;
  justify-content: flex-end;
}

.main-menu > ul {
  display: flex;
  flex-wrap: wrap;
}

.main-menu > ul > li {
  position: relative;
}

.main-menu > ul > li + li {
  margin-left: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .main-menu > ul > li + li {
    margin-left: 40px;
  }
}

.main-menu > ul > li > a {
  font-size: 15px;
  line-height: 100px;
  text-decoration: none;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 600;
}

.main-menu > ul > li > a:hover, .main-menu > ul > li > a.active {
  color: #ffb300;
}

.main-menu > ul > li.has-children > a::after {
  content: "\f107";
  font-family: 'fontAwesome';
  padding-left: 5px;
}

.main-menu > ul > li:hover .sub-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}

.main-menu > ul .sub-menu {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: auto;
  width: 230px;
  padding: 15px 0;
  border-bottom: 3px solid #ffb300;
  margin-top: 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  z-index: 9;
}

.main-menu > ul .sub-menu::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 25px;
  text-align: center;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 10px 7.5px;
  border-color: transparent transparent #f7f7f7 transparent;
}

.main-menu > ul .sub-menu li a {
  font-weight: 400;
  letter-spacing: 0.5px;
  display: block;
  padding: 5px 20px;
  text-transform: uppercase;
}

/*-- Mobile Menu Bar --*/
.main-menu-right-side {
  justify-content: flex-end;
}

.main-menu-right-side a {
  font-size: 20px;
  line-height: 100px;
  color: #3d3d3d;
}

.main-menu-right-side a:hover {
  color: #ffb300;
}

@media only screen and (max-width: 479px) {
  .main-menu-right-side a {
    line-height: 80px;
  }
}

/* ======== 2.3 Mobile Menu ========*/
.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  transition-duration: 700ms;
  z-index: 9999;
}

.mobile-menu-wrapper.open {
  opacity: 1;
  visibility: visible;
}

.mobile-menu-wrapper.open .mobile-menu-inner {
  transform: none;
}

.mobile-menu-inner {
  width: 100%;
  height: 100%;
  width: 350px;
  background-color: #f7f7f7;
  position: relative;
  transform: translateX(calc(100% - 50px));
  transition: all 0.3s ease 0s;
  transition-duration: 700ms;
  margin-left: auto;
  position: relative;
  padding: 20px 0 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.mobile-menu-inner .btn-close-bar {
  display: flex;
  justify-content: flex-start;
  font-size: 50px;
  padding-left: 20px;
  color: #000000;
  opacity: 1;
}

.mobile-menu-inner .btn-close-bar i {
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s ease 0s;
}

.mobile-menu-inner .btn-close-bar i:hover {
  color: #ffb300;
  transition: all 0.3s ease 0s;
  transform: rotate(90deg);
}

.mobile-menu-inner .widget-social {
  justify-content: flex-start;
  padding-left: 40px;
}

.mobile-menu {
  text-align: start;
  padding: 10px 40px 30px;
}

.mobile-menu > .has-children > a i {
  transform: rotate(0deg);
  transition: all 0.3s ease 0s;
}

.mobile-menu > .has-children .dropdown a {
  font-size: 16px;
  padding-top: 5px;
  display: block;
  padding-left: 15px;
}

.mobile-menu > .has-children.active > a {
  color: #ffb300;
}

.mobile-menu > .has-children.active > a i {
  transform: rotate(180deg);
  transition: all 0.3s ease 0s;
}

.mobile-menu > li {
  margin-bottom: 15px;
}

.mobile-menu > li > a {
  color: #000000;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.mobile-menu > li > a:hover {
  color: #ffb300;
}

.contact-links {
  text-align: left;
  padding: 0 20px 0 40px;
  margin-bottom: 40px;
}

.contact-links li {
  color: #2b2b35;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.contact-links li a {
  color: #2b2b35;
}

.contact-links li a:hover {
  color: #ffb300;
}

.contact-links li i {
  color: #ffb300;
  margin-right: 10px;
  font-size: 22px;
}

/* ======== 2.4 Offcanvas Search ========*/
.offcanvas-search {
  position: fixed;
  top: 0;
  left: 0;
  right: auto;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  transition-duration: 700ms;
  z-index: 9999;
}

.offcanvas-search.open {
  opacity: 1;
  visibility: visible;
}

.offcanvas-search.open .offcanvas-search-inner {
  transform: none;
}

.offcanvas-search-inner {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;
  transform: translateX(calc(100% - 0px));
  transition: all 0.3s ease 0s;
  transition-duration: 700ms;
  padding: 20px 20px 40px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-flow: row-reverse;
}

.offcanvas-search-inner .btn-close-bar {
  font-size: 50px;
  color: #000000;
  opacity: 1;
}

.offcanvas-search-inner .btn-close-bar i {
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s ease 0s;
}

.offcanvas-search-inner .btn-close-bar i:hover {
  color: #ffb300;
  transition: all 0.3s ease 0s;
  transform: rotate(90deg);
}

.offcanvas-search-form {
  width: 100%;
}

.offcanvas-search-form input {
  width: 100%;
  border: 0px;
  border-bottom: 2px solid #000000;
  font-size: 24px;
}

.offcanvas-search-form input:focus {
  outline: none;
}

/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/
.hero-slide-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 690px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-item {
    height: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slide-item {
    height: 400px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-slide-item {
    height: 350px;
  }
}

.hero-slide-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.hero-slide-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-slide-content {
  position: relative;
  z-index: 99;
}

.hero-slide-content .sub-title {
  font-weight: 400;
  line-height: 1.16;
  color: #ffffff;
  margin-bottom: 20px;
  display: block;
  font-size: 18px;
}

.hero-slide-content .title {
  color: #ffffff;
  font-size: 72px;
  line-height: 1.05;
  margin-bottom: 10px;
}

.hero-slide-content p {
  font-size: 16px;
  line-height: 1.937;
  max-width: 450px;
  margin-bottom: 35px;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .hero-slide-content .title {
    font-size: 36px;
    margin-bottom: 15px;
  }
  .hero-slide-content p {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-slide-content .sub-title {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .hero-slide-content .title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .hero-slide-content p {
    font-size: 14px;
    line-height: 1.8;
  }
}

.hero-slider .main-slider-nav {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.25);
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  font-size: 28px;
}

.hero-slider .main-slider-nav::after {
  display: none;
}

.hero-slider .main-slider-nav:hover {
  color: #ffb300;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider .main-slider-nav {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider .main-slider-nav {
    display: none;
  }
}

.hero-slider .home-slider-prev {
  left: 30px;
}

@media only screen and (max-width: 575px) {
  .hero-slider .home-slider-prev {
    left: 5px;
  }
}

.hero-slider .home-slider-next {
  right: 30px;
}

.hero-slider .home-slider-next::after {
  display: none;
}

@media only screen and (max-width: 575px) {
  .hero-slider .home-slider-next {
    right: 5px;
  }
}

.hero-slider:hover .main-slider-nav {
  opacity: 1;
  visibility: visible;
}

.hero-slider .home-slider-prev, .hero-slider .home-slider-next {
  margin-top: 0;
  transform: translateY(-50%);
}

.hero-slider .swiper-pagination {
  position: absolute;
  bottom: 20px;
  display: none;
}

@media only screen and (max-width: 767px) {
  .hero-slider .swiper-pagination {
    display: block;
  }
}

.hero-slide-content > * {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOutUp;
}

.swiper-slide-active .hero-slide-content > * {
  animation-name: fadeInUp;
}

.swiper-slide-active .hero-slide-content > *:nth-child(1) {
  animation-delay: 0.5s;
}

.swiper-slide-active .hero-slide-content > *:nth-child(2) {
  animation-delay: 1s;
}

.swiper-slide-active .hero-slide-content > *:nth-child(3) {
  animation-delay: 1.5s;
}

.swiper-slide-active .hero-slide-content > *:nth-child(4) {
  animation-delay: 2s;
}

.swiper-slide-active .hero-slide-content > *:nth-child(5) {
  animation-delay: 2.5s;
}

.swiper-slide-active .hero-slide-content > *:nth-child(6) {
  animation-delay: 3s;
}

/*----------------------------------------*/
/*  04. Component CSS
/*----------------------------------------*/
/* ======== 4.1 Button CSS ========*/
.btn {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 28px;
  border-width: 2px;
  line-height: 1;
}

.btn:focus {
  box-shadow: none !important;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 12px 25px;
}

@media only screen and (max-width: 767px) {
  .btn {
    padding: 12px 20px;
    font-size: 12px;
  }
}

/* Button Color & Style (Solid & Outline) */
.btn-primary {
  border-color: #ffb300;
  background-color: #ffb300;
  color: #ffffff;
}

.btn-primary:hover, .btn-primary:focus {
  border-color: transparent;
  background-color: #d99800;
  color: #ffffff;
}

.btn-outline-primary {
  color: #ffb300;
  border-color: #ffb300;
  background-color: transparent;
}

.btn-outline-primary:hover {
  border-color: transparent;
  background-color: #ffb300;
  color: #ffffff;
}

.btn-secondary {
  border-color: #2b2b35;
  background-color: #2b2b35;
  color: #ffffff;
}

.btn-secondary:hover, .btn-secondary:focus {
  border-color: transparent;
  background-color: #1a1a20;
  color: #ffffff;
}

.btn-outline-secondary {
  color: #2b2b35;
  border-color: #2b2b35;
  background-color: transparent;
}

.btn-outline-secondary:hover {
  border-color: transparent;
  background-color: #2b2b35;
  color: #ffffff;
}

.btn-default-color {
  border-color: #666666;
  background-color: #666666;
  color: #ffffff;
}

.btn-default-color:hover, .btn-default-color:focus {
  border-color: transparent;
  background-color: #535353;
  color: #ffffff;
}

.btn-outline-default-color {
  color: #666666;
  border-color: #666666;
  background-color: transparent;
}

.btn-outline-default-color:hover {
  border-color: transparent;
  background-color: #666666;
  color: #ffffff;
}

.btn-light {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #ffb300;
}

.btn-light:hover, .btn-light:focus {
  border-color: transparent;
  background-color: #ececec;
  color: #000000;
}

.btn-outline-light {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
}

.btn-outline-light:hover {
  border-color: transparent;
  background-color: #ffffff;
  color: #000000;
}

.btn-matterhorn {
  border-color: #4d4d4d;
  background-color: #4d4d4d;
  color: #ffffff;
}

.btn-matterhorn:hover, .btn-matterhorn:focus {
  border-color: transparent;
  background-color: #3a3a3a;
  color: #ffffff;
}

.btn-outline-matterhorn {
  color: #4d4d4d;
  border-color: #4d4d4d;
  background-color: transparent;
}

.btn-outline-matterhorn:hover {
  border-color: transparent;
  background-color: #4d4d4d;
  color: #ffffff;
}

.btn-gray {
  border-color: #f7f7f7;
  background-color: #f7f7f7;
  color: #ffffff;
}

.btn-gray:hover, .btn-gray:focus {
  border-color: transparent;
  background-color: #e4e4e4;
  color: #ffffff;
}

.btn-outline-gray {
  color: #f7f7f7;
  border-color: #f7f7f7;
  background-color: transparent;
}

.btn-outline-gray:hover {
  border-color: transparent;
  background-color: #f7f7f7;
  color: #ffffff;
}

/* Button Hover Color & Style (Solid & Outline) */
.btn-hover-primary:hover, .btn-hover-primary:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background-color: #ffb300;
  color: #ffffff;
}

.btn-outline-hover-primary:hover, .btn-outline-hover-primary:not(:disabled):not(.disabled).active {
  color: #ffb300;
  border-color: transparent;
  background-color: transparent;
}

.btn-hover-secondary:hover, .btn-hover-secondary:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background-color: #2b2b35;
  color: #ffffff;
}

.btn-outline-hover-secondary:hover, .btn-outline-hover-secondary:not(:disabled):not(.disabled).active {
  color: #2b2b35;
  border-color: transparent;
  background-color: transparent;
}

.btn-hover-default-color:hover, .btn-hover-default-color:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background-color: #666666;
  color: #ffffff;
}

.btn-outline-hover-default-color:hover, .btn-outline-hover-default-color:not(:disabled):not(.disabled).active {
  color: #666666;
  border-color: transparent;
  background-color: transparent;
}

.btn-hover-light:hover, .btn-hover-light:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background-color: #ffffff;
  color: #000000;
}

.btn-outline-hover-light:hover, .btn-outline-hover-light:not(:disabled):not(.disabled).active {
  color: #ffffff;
  border-color: transparent;
  background-color: transparent;
}

.btn-hover-matterhorn:hover, .btn-hover-matterhorn:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background-color: #4d4d4d;
  color: #ffffff;
}

.btn-outline-hover-matterhorn:hover, .btn-outline-hover-matterhorn:not(:disabled):not(.disabled).active {
  color: #4d4d4d;
  border-color: transparent;
  background-color: transparent;
}

.btn-hover-gray:hover, .btn-hover-gray:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background-color: #f7f7f7;
  color: #ffffff;
}

.btn-outline-hover-gray:hover, .btn-outline-hover-gray:not(:disabled):not(.disabled).active {
  color: #f7f7f7;
  border-color: transparent;
  background-color: transparent;
}

/* ======== 4.2 Team CSS ========*/
.single-team-member {
  border: 1px solid #dcd9d9;
  position: relative;
}

.single-team-member:before {
  position: absolute;
  content: '';
  background: #ffb300;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 0%;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.single-team-member:hover:before {
  opacity: 1;
  visibility: visible;
  width: 100%;
}

.team-member-image img {
  width: 100%;
}

.team-member-content {
  padding: 28px 0 20px;
  text-align: center;
}

.team-member-content .name {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: #000000;
  display: block;
  line-height: 1;
  margin-bottom: 10px;
}

.team-member-content .person-title {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  display: block;
  line-height: 1;
  margin-bottom: 10px;
  color: #000000;
}

.team-member-content .social-widgets {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team-member-content .social-widgets li a {
  padding: 10px 15px;
}

/* ======== 4.3 Funfact CSS ========*/
.funfact-bg {
  background-image: url(../images/bg/funfact-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding: 100px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .funfact-bg {
    padding: 80px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .funfact-bg {
    padding: 60px 0px;
  }
}

.funfact-bg:after {
  content: "";
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  z-index: 2;
}

.funfact-wrap {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.funfact-wrap .funfact-icon {
  height: 100px;
  width: 100px;
  background: #ffb300;
  border-radius: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.funfact-wrap .odometer {
  display: block;
  line-height: 1;
  font-size: 46px;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .funfact-wrap .odometer {
    font-size: 40px;
  }
}

.funfact-wrap .title {
  display: block;
  line-height: 1;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 0;
}

/* ======== 4.4 Testimonial CSS ========*/
.testimonial .text {
  position: relative;
}

.testimonial .text p {
  background: #f7f7f7;
  padding: 30px 27px;
  font-style: italic;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  margin-bottom: 25px;
}

.testimonial .text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  border-width: 11px 10px 6px 17px;
  border-style: solid;
  border-color: #d6d6d6 #d6d6d6 transparent transparent;
}

.testimonial .thumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 30px;
}

.testimonial .thumb img {
  width: 90px;
  height: 90px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 15px;
}

.testimonial .thumb .name .title {
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  display: block;
  line-height: 1;
  margin-bottom: 8px;
}

.testimonial .thumb .name .sub-title {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  display: block;
  line-height: 1;
}

/* ======== 4.5 Brand Logo CSS ========*/
.brand-logo-bg {
  padding: 100px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-bg {
    padding: 80px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-bg {
    padding: 60px 0px;
  }
}

/* ======== 4.6 Breadcrumb CSS ========*/
.breadcrumb-bg {
  background-image: url(../images/bg/breadcrumb-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.breadcrumb-bg::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #ffb300;
  opacity: .6;
}

.cr-breadcrumb-area {
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .cr-breadcrumb-area {
    flex-direction: column;
  }
  .cr-breadcrumb-area .title {
    text-align: center;
    padding-bottom: 10px;
  }
}

.cr-breadcrumb-area .title {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 1;
  margin-bottom: 0px;
}

@media only screen and (max-width: 575px) {
  .cr-breadcrumb-area .title {
    font-size: 26px;
  }
}

.cr-breadcrumb-area .breadcrumb-list {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
}

.cr-breadcrumb-area .breadcrumb-list li {
  color: #ffffff;
  display: flex;
  align-items: center;
}

.cr-breadcrumb-area .breadcrumb-list li:not(:first-child):before {
  content: "/";
  padding: 0 5px;
}

.cr-breadcrumb-area .breadcrumb-list li a {
  color: #ffffff;
  transition: all 0.3s ease 0s;
  font-size: 16px;
  font-weight: 400;
}

.cr-breadcrumb-area .breadcrumb-list li a:hover {
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}

/* ======== 4.7 Pagination CSS ========*/
.pagination-cr {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-cr li {
  margin-right: 10px;
}

.pagination-cr li:first-child {
  margin-left: 0;
  margin-right: 15px;
}

.pagination-cr li:first-child a {
  width: auto;
  border: none;
  text-transform: uppercase;
  display: inline-block;
}

.pagination-cr li:first-child a:hover {
  background: none;
  color: #ffb300;
}

.pagination-cr li:first-child a i {
  font-size: 14px;
  padding-right: 5px;
}

.pagination-cr li:last-child {
  margin-right: 0;
  margin-left: 15px;
}

.pagination-cr li:last-child a {
  width: auto;
  border: none;
  text-transform: uppercase;
  display: inline-block;
}

.pagination-cr li:last-child a i {
  font-size: 14px;
  padding-left: 5px;
}

.pagination-cr li:last-child a:hover {
  background: none;
  color: #ffb300;
}

.pagination-cr li a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  display: flex;
  justify-content: center;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.pagination-cr li a:hover {
  color: #ffffff;
  border-color: #ffb300;
  background: #ffb300;
}

.pagination-cr li.active a {
  color: #ffffff;
  background: #ffb300;
  border-color: #ffb300;
}

/*----------------------------------------*/
/*  05. Pages CSS
/*----------------------------------------*/
/* ======== 5.1 About CSS ========*/
.feature-box-wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
}

.feature-box-wrapper .feature-box-icon {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  text-align: center;
  line-height: 70px;
  padding: 15px;
  border: 2px solid #ffb300;
  transition: .3s;
}

@media only screen and (max-width: 575px) {
  .feature-box-wrapper .feature-box-icon {
    height: 90px;
    width: 90px;
  }
}

.feature-box-wrapper .feature-content {
  margin-left: 20px;
  width: 65%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-box-wrapper .feature-content {
    width: 58%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .feature-box-wrapper .feature-content {
    width: 75%;
  }
}

.feature-box-wrapper .feature-content .title {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.feature-box-wrapper.feature-box-border {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.feature-box-wrapper.feature-box-border:not(:last-child):before {
  content: '';
  position: absolute;
  background: #ececec;
  left: 12%;
  bottom: 0;
  width: 100%;
  height: 1px;
}

.feature-box-wrapper.feature-box-border .feature-content {
  width: 75%;
  margin-left: 20px;
}

.feature-box-wrapper.feature-box-column {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.feature-box-wrapper.feature-box-column .feature-content {
  width: 300px;
  margin-left: 0px;
}

.feature-box-wrapper:hover .feature-box-icon {
  background-color: #ffb300;
  border-color: #ffb300;
  transition: .3s;
}

.feature-box-wrapper:hover .feature-box-icon img {
  filter: brightness(0) invert(1);
  transition: .3s;
}

.about-timeline-spacing-bottom {
  margin-bottom: 60px;
  padding-bottom: 53px;
}

.about-timeline-spacing-top {
  margin-top: 60px;
  padding-top: 60px;
}

.about-content-area .title {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.16;
  margin-bottom: 0;
  margin-top: -7px;
  color: #000000;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .about-content-area .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .about-content-area .title {
    font-size: 26px;
  }
}

.about-content-area .title:before {
  position: absolute;
  bottom: 0;
  content: '';
  width: 100px;
  height: 2px;
  background: #ffb300;
}

.about-image img {
  width: 100%;
}

/* ======== 5.2 Project CSS ========*/
.project-tab {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .project-tab {
    display: inline-table;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .project-tab.nav-tab {
    display: flex;
    text-align: center;
  }
}

.project-tab .project-filter, .project-tab .nav-link {
  font-size: 13px;
  border: 0;
  color: #666666;
  font-weight: 500;
  padding: 15px 25px;
  margin-left: 5px;
  letter-spacing: .5px;
  line-height: 1.2;
  background: #ffffff;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .project-tab .project-filter, .project-tab .nav-link {
    padding: 12px 15px;
  }
}

.project-tab .project-filter:focus, .project-tab .nav-link:focus {
  outline: none;
}

.project-tab .project-filter:hover, .project-tab .project-filter.is-checked, .project-tab .nav-link:hover, .project-tab .nav-link.is-checked {
  background: #ffb300;
  color: #ffffff;
}

.project-tab .nav-link {
  color: #ffffff;
  border: 1px solid #ffffff;
  background: transparent;
}

.project-tab .nav-link:hover, .project-tab .nav-link.active {
  background: #ffb300;
  color: #ffffff;
  border: 1px solid #ffb300;
}

.project-image img {
  width: 100%;
  height: 100%;
}

.project-content {
  margin-top: 27px;
}

.project-content .title {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
}

.project-content .title a {
  color: #ffffff;
}

.project-content .title a:hover {
  color: #ffb300;
}

.project-content .link {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  color: #ffffff;
  font-weight: 500;
  display: block;
  text-decoration: underline;
  letter-spacing: .6px;
}

.project-content .link:hover {
  color: #ffb300;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}

.project-content.project-content-dark .title a {
  color: #2b2b35;
}

.project-content.project-content-dark .title a:hover {
  color: #ffb300;
}

.project-content.project-content-dark .project-tag li:after {
  color: #2b2b35;
}

.project-content.project-content-dark .project-tag li a {
  color: #2b2b35;
}

.project-content.project-content-dark .project-tag li a:hover {
  color: #ffb300;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}

.project-tag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.project-tag li:after {
  content: "/";
  padding: 0 5px;
  color: #ffffff;
}

.project-tag li a {
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  font-weight: 400;
  letter-spacing: .5px;
}

.project-tag li a:hover {
  color: #ffb300;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}

.project-tag li:last-child:after {
  display: none;
}

.tab-carousel:hover .swiper-button-next,
.tab-carousel:hover .swiper-button-prev {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}

.project-carousel {
  position: relative;
}

.project-carousel .swiper-button-prev {
  left: -80px;
}

.project-carousel .swiper-button-next {
  right: -80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .project-carousel .swiper-button-prev {
    left: 0px;
  }
  .project-carousel .swiper-button-next {
    right: 0px;
  }
}

.project-carousel .swiper-button-next,
.project-carousel .swiper-button-prev {
  border: 1px solid #ffffff;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 18px;
  line-height: 18px;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.project-carousel .swiper-button-next:after,
.project-carousel .swiper-button-prev:after {
  display: none;
}

.project-carousel .swiper-button-next:focus,
.project-carousel .swiper-button-prev:focus {
  outline: none;
}

.project-carousel .swiper-button-next:hover,
.project-carousel .swiper-button-prev:hover {
  background: #ffb300;
  border-color: #ffb300;
  transition: all 0.3s ease 0s;
}

.single-project-fullwidth .project-content {
  text-align: center;
  margin-top: 0;
  padding: 30px 0;
  background: #f1f1f1;
}

.single-project-fullwidth .project-content .title a {
  color: #5f5f5f;
}

.single-project-fullwidth .project-content .title a:hover {
  color: #ffb300;
}

.single-project-fullwidth .project-tag {
  justify-content: center;
}

.single-project-fullwidth .project-tag li:after {
  color: #5f5f5f;
}

.single-project-fullwidth .project-tag li a {
  color: #5f5f5f;
}

.single-project-fullwidth .project-tag li a:hover {
  color: #ffb300;
}

.single-project-fullwidth .project-tag li:last-child:after {
  display: none;
}

.project-overlay {
  position: relative;
  display: block;
  overflow: hidden;
}

.project-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  color: #ffffff;
}

.project-overlay img {
  transition: 0.4s;
}

.single-project:hover .project-overlay:before {
  opacity: 1;
  visibility: visible;
}

.single-project:hover .project-overlay img {
  transform: scale(1.1) rotate(1.2deg);
  transition: 0.4s;
}

.project-info .title, .project-desc .title {
  font-size: 22px;
}

.project-info li, .project-desc li {
  margin-bottom: 5px;
  line-height: 1.8;
}

.project-info li span, .project-desc li span {
  font-weight: 700;
  font-size: 16px;
  margin-right: 5px;
}

/* ======== 5.3 Blog CSS ========*/
.blog-content .post-meta {
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  margin-top: 30px;
  font-weight: 400;
  font-family: #000000;
  color: #8c8c8c;
}

.blog-content .post-meta span:after {
  content: "/";
  padding: 0 5px;
}

.blog-content .post-meta span:last-child:after {
  display: none;
}

.blog-content .post-meta span a {
  color: #8c8c8c;
}

.blog-content .post-meta span a:hover {
  color: #ffb300;
}

.blog-content .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.16;
  display: block;
  margin-top: 15px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
  .blog-content .title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-content .title {
    font-size: 20px;
  }
}

.blog-content .title a {
  color: #000000;
}

.blog-content .title a:hover {
  color: #ffb300;
}

.blog-content p {
  margin-bottom: 15px;
}

.blog-content .link {
  text-decoration: underline;
  text-transform: uppercase;
  color: #000000;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  line-height: 1;
}

.blog-content .link:before {
  content: "";
  position: relative;
  left: 0;
  width: 0;
  height: 2px;
  display: block;
  margin-right: 0px;
  color: #ffb300;
  background: #ffb300;
  transition: .3s;
}

.blog-content .link:hover {
  color: #ffb300;
}

.blog-content .link:hover:before {
  content: "";
  position: relative;
  left: 0;
  width: 20px;
  height: 2px;
  display: block;
  margin-right: 5px;
  color: #ffb300;
  background: #ffb300;
  transition: .3s;
}

.blog-single-post-wrapper .blog-overlay {
  position: relative;
  display: block;
  overflow: hidden;
}

.blog-single-post-wrapper .blog-overlay img {
  transition: 0.4s;
  width: 100%;
}

.blog-single-post-wrapper:hover .blog-overlay img {
  transform: scale(1.1) rotate(1.2deg);
  transition: 0.4s;
}

/*-- Blog Details --*/
.blog-details .image img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.blog-details .content .title {
  font-size: 30px;
  font-weight: 700;
  color: #000000;
}

@media only screen and (max-width: 575px) {
  .blog-details .content .title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-details .content .title {
    font-size: 22px;
  }
}

.blog-details .content .meta-list {
  display: flex;
  flex-wrap: wrap;
  color: #686868;
}

.blog-details .content .meta-list .meta-item {
  font-size: 14px;
  margin-right: 10px;
}

.blog-details .content .meta-list .meta-item:last-child {
  margin-right: 0;
}

.blog-details .content .meta-list .meta-item + .meta-item::before {
  content: "|";
  margin-right: 10px;
}

.blog-details .content .meta-list a:hover {
  color: #ffb300;
}

.blog-details .blockquote {
  font-size: 16px;
  line-height: 2;
  position: relative;
  background-color: #ececec;
  color: #000000;
  font-weight: 600;
  padding: 50px 35px 35px;
}

@media only screen and (max-width: 575px) {
  .blog-details .blockquote {
    font-size: 14px;
  }
}

.blog-details .blockquote:before {
  background-color: #ffb300;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
}

.blog-details .blockquote:after {
  content: "\f10e";
  color: #8c8c8c;
  font-family: "FontAwesome";
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 25px;
}

@media only screen and (max-width: 575px) {
  .blog-details .blockquote:after {
    font-size: 20px;
    top: 30px;
    right: 15px;
  }
}

.blog-details .blockquote .blockquote-footer {
  color: #666666;
  font-size: 15px;
  font-weight: 400;
}

.blog-details .share-article {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.blog-details .share-article .left-side {
  float: left;
}

.blog-details .share-article .right-side {
  float: right;
}

@media only screen and (max-width: 479px) {
  .blog-details .share-article h6, .blog-details .share-article .h6 {
    display: none;
  }
}

.comment-area-wrapper .title {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}

.comment-area-wrapper .single-comment-wrap {
  display: flex;
  flex-direction: row;
}

.comment-area-wrapper .single-comment-wrap.comment-reply {
  padding-left: 50px;
}

@media only screen and (max-width: 479px) {
  .comment-area-wrapper .single-comment-wrap.comment-reply {
    padding-left: 20px;
  }
}

.comment-area-wrapper .single-comment-wrap .author-thumb {
  margin-right: 10px;
  width: 80px;
  height: 80px;
}

.comment-area-wrapper .single-comment-wrap .author-thumb img {
  width: 100%;
  border-radius: 10px;
}

@media only screen and (max-width: 479px) {
  .comment-area-wrapper .single-comment-wrap .author-thumb {
    width: 50px;
    height: 50px;
  }
}

.comment-area-wrapper .single-comment-wrap .comments-info {
  width: 90%;
}

.comment-area-wrapper .single-comment-wrap .comments-info .author {
  font-size: 12px;
  color: #000000;
}

.comment-area-wrapper .single-comment-wrap .comments-info .author a {
  color: #000000;
  font-size: 14px;
}

.comment-area-wrapper .single-comment-wrap .comments-info .author a:hover {
  color: #ffb300;
}

.comment-area-wrapper .single-comment-wrap .comments-info .btn-reply {
  font-size: 12px;
  color: #000000;
}

.comment-area-wrapper .single-comment-wrap .comments-info .btn-reply:hover {
  color: #ffb300;
}

.blog-comment-form-wrapper .title {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}

.blog-comment-form-wrapper .comment-box .input-area {
  padding: 10px 15px;
  background: #ececec;
  border: 0px;
}

.blog-comment-form-wrapper .comment-box .input-area:focus {
  border: 0px;
  outline: 0px;
  box-shadow: none;
}

/*------- Sidebar Blog ----*/
.sidebar {
  float: left;
  width: 100%;
  margin-top: 40px;
}

.sidebar:first-child {
  margin-top: 0;
}

.sidebar .sidebar-title {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-size: 24px;
  text-transform: capitalize;
  color: #000000;
  line-height: 1;
  font-weight: 700;
}

.sidebar .sidebar-title::after {
  content: "";
  background: #ffb300;
  width: 70px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0px;
}

@media only screen and (max-width: 479px) {
  .sidebar .sidebar-title {
    font-size: 20px;
  }
}

/*Sidebar Search*/
.sidebar-search form {
  position: relative;
  max-width: 100%;
}

.sidebar-search form input {
  font-size: 14px;
  width: 100%;
  border: none;
  line-height: 30px;
  padding: 10px 20px;
  padding-right: 50px;
  background-color: #f7f7f7;
  color: #000000;
}

.sidebar-search form input:focus {
  border: 0;
  outline: none;
  border-radius: 0;
}

.sidebar-search form button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #000000;
  padding: 0 17px;
  transition: .3s;
}

.sidebar-search form button:hover {
  color: #ffb300;
  transition: .3s;
}

.sidebar-search form button i {
  font-size: 18px;
  line-height: 50px;
  display: block;
}

/*Sidebar Link*/
.sidebar-link {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-link li {
  margin-top: 20px;
}

.sidebar-link li:first-child {
  margin-top: 0px;
}

.sidebar-link li a {
  display: block;
  position: relative;
  line-height: 1;
  font-weight: 400;
  font-size: 15px;
}

.sidebar-link li a span {
  font-size: 85%;
}

/*Sidebar Blog*/
.sidebar-blog {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-blog li {
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.sidebar-blog li:first-child {
  margin-top: 0px;
}

.sidebar-blog li .image {
  flex: 1 0 80px;
  margin-right: 15px;
}

.sidebar-blog li .content {
  flex: 1 0 calc(100% - 95px);
}

.sidebar-blog li .content .title {
  display: block;
  font-weight: 500;
  line-height: 1.35;
  color: #000000;
}

.sidebar-blog li .content .title:hover {
  color: #ffb300;
}

.sidebar-blog li .content .date {
  display: block;
  font-size: 13px;
  line-height: 1;
  margin-top: 10px;
}

/*Sidebar Tags*/
.sidebar-tags {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}

.sidebar-tags a {
  font-size: 13px;
  color: #000000;
  background-color: transparent;
  padding: 6px 20px;
  margin: 3px;
  border: 1px solid #dcd9d9;
}

.sidebar-tags a:hover {
  border-color: #ffb300;
  background-color: #ffb300;
  color: #ffffff;
}

/* ======== 5.4 Contact CSS ========*/
.single-contact-address {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.single-contact-address:hover .contact-address-icon {
  background: #ffb300;
  transition: all 0.4s ease-in-out 0s;
}

.single-contact-address:hover .contact-address-icon:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1.1);
}

.single-contact-address .title {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 20px;
}

.single-contact-address p {
  line-height: 1.4;
  margin-bottom: 10px;
}

.single-contact-address p:last-child {
  margin-bottom: 0;
}

.contact-address-icon {
  background: #2b2b35;
  color: #ffffff;
  padding: 30px;
  font-size: 36px;
  display: flex;
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
  transition: all 0.4s ease-in-out 0s;
}

.contact-address-icon:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  border: 2px solid #ffb300;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out 0s;
}

.contact-form {
  color: #000000;
  font-size: 20px;
}

.contact-form .input-item {
  padding: 10px 20px;
}

.contact-form .input-item, .contact-form .textarea-item {
  width: 100%;
  border: 0;
  font-size: 14px;
  background: #dcd9d9;
}

.contact-form .input-item:focus, .contact-form .textarea-item:focus {
  border: 0px;
  outline: 0px;
}

.contact-form .textarea-item {
  min-height: 130px;
  padding: 10px 20px;
}

p.form-messege {
  font-size: 16px;
  margin-bottom: 0;
  display: inline-block;
  padding-top: 30px;
  line-height: 1;
  display: none;
}

p.form-messege.success {
  display: block;
}

p.form-messege.error {
  display: block;
}

/* ======== 5.5 Faq CSS ========*/
.faq_content_area {
  padding: 0px 0 65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq_content_area {
    padding: 0px 0 30px;
  }
}

.accordion_area .card-header {
  background: #ffb300;
}

.accordion_area .card-header:first-child {
  border-radius: inherit;
}

.card_dipult {
  border: none;
  margin-bottom: 10px;
  border-radius: 5px;
}

@media only screen and (max-width: 479px) {
  .card_dipult .card-body p {
    font-size: 12px;
  }
}

.card_dipult:last-child {
  margin-bottom: 0;
}

.card-header.card_accor {
  padding: 0;
  border: none;
}

.card-header.card_accor button {
  height: 45px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  white-space: initial;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-header.card_accor button {
    height: 60px;
  }
}

.card-header.card_accor button:focus {
  box-shadow: none;
}

.card-header.card_accor button i {
  position: absolute;
  top: 50%;
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
  right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-header.card_accor button i {
    right: 10px;
  }
}

.card-header.card_accor button.btn-link {
  border-bottom: 1px dotted #ffb300;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding-left: 20px;
  height: 100%;
  line-height: 1.6;
  padding-right: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-header.card_accor button.btn-link {
    padding: 10px 28px 10px 10px;
    font-size: 13px;
  }
}

.card-header.card_accor button.btn-link.collapsed {
  background: #f7f7f7;
  border-bottom: 1px dotted #ffb300;
  width: 100%;
  text-align: left;
  color: #000000;
}

.card-header.card_accor button.btn-link i.fa-plus {
  display: none;
}

.card-header.card_accor button.btn-link.collapsed i.fa-plus {
  display: block;
}

.card-header.card_accor button.btn-link.collapsed i.fa-minus {
  display: none;
}

.faq_content_wrapper .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 1.5;
  margin-top: -8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq_content_wrapper .title {
    font-size: 14px;
    line-height: 24px;
  }
}

/* ======== 5.6 Error 404 CSS ========*/
.error-area img {
  width: 600px;
  object-fit: cover;
}

@media only screen and (max-width: 767px), only screen and (max-width: 575px), only screen and (max-width: 479px) {
  .error-area img {
    width: 100%;
  }
}

.error-area .title {
  margin-top: 60px;
  margin-bottom: 10px;
  line-height: 1;
  font-size: 36px;
}

@media only screen and (max-width: 575px), only screen and (max-width: 479px) {
  .error-area .title {
    font-size: 26px;
    margin-top: 40px;
  }
}

.error-area .error-btn_wrap {
  margin-top: 50px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .error-area .error-btn_wrap {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 575px), only screen and (max-width: 479px) {
  .error-area .error-btn_wrap {
    margin-top: 20px;
  }
}

/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/
/* ======== 6.1 Footer Top CSS ========*/
.footer-top {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-top {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-top {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.single-footer-widget .widget-title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  line-height: 1;
  display: block;
}

.single-footer-widget .widget-title:after {
  content: "";
  background: #ffb300;
  width: 40px;
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 2px;
}

.single-footer-widget .desc-content {
  color: #ffffff;
}

.single-footer-widget .widget-address li {
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  line-height: 1;
}

.single-footer-widget .widget-address li:last-child {
  margin-bottom: 0px;
}

.single-footer-widget .widget-address li i {
  font-size: 18px;
  margin-right: 10px;
  color: #ffb300;
}

.single-footer-widget .widget-address li a {
  color: #ffffff;
}

.single-footer-widget .widget-address li a:hover {
  color: #ffb300;
}

.single-footer-widget .widget-list li {
  margin-bottom: 10px;
}

.single-footer-widget .widget-list li a {
  color: #ffffff;
  display: block;
  line-height: 1.9;
}

.single-footer-widget .widget-list li a:hover {
  color: #ffb300;
  padding-left: 5px;
}

.single-footer-widget .widget-list li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .widget-social {
  display: flex;
  flex-wrap: wrap;
}

.single-footer-widget .widget-social a {
  padding: 10px;
  margin-right: 10px;
  color: #ffffff;
  border: 1px solid #8b8b8b;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: transparent;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .single-footer-widget .widget-social a {
    margin-right: 8px;
  }
}

.single-footer-widget .widget-social a:hover {
  background: #ffb300;
  color: #000000;
  border-color: #ffb300;
}

.newsletter-form-wrap .mc-form {
  display: flex;
  align-items: center;
  position: relative;
}

.newsletter-form-wrap .mc-form .email-box {
  padding: 5px 35px 5px 10px;
  border-radius: 0;
  background: transparent;
  border: 1px solid #8b8b8b;
  border-right: 0;
  color: #ffffff;
  min-height: 41px;
}

.newsletter-form-wrap .mc-form .email-box:focus {
  box-shadow: none;
}

.newsletter-form-wrap .mc-form .email-box::placeholder {
  color: #ececec;
  opacity: 1;
}

.newsletter-form-wrap .mc-form .newsletter-btn {
  padding: 0 10px 0 0;
  border: 1px solid #8b8b8b;
  border-left: 0;
  border-radius: 0;
  background: transparent;
  color: #ffffff;
  min-height: 41px;
  position: absolute;
  top: 0;
  right: 0;
}

.newsletter-form-wrap .mc-form .newsletter-btn:hover {
  color: #ffb300;
}

.newsletter-form-wrap .mc-form .newsletter-btn:focus {
  outline: none;
}

/* ======== 6.2 Footer Bottom CSS ========*/
.footer-bottom {
  padding: 15px 0;
  border-top: 1px solid #8b8b8b;
}

.copyright-content p {
  color: #ffffff;
}

.copyright-content p a {
  color: #ffffff;
}

.copyright-content p a:hover {
  color: #ffb300;
}
