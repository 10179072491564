/* ======== 4.3 Funfact CSS ========*/
.funfact-bg  {
    background-image: url(../images/bg/funfact-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 100px 0px;
    
    @media #{$tablet-device} {
        padding: 80px 0px;
    }
    @media #{$large-mobile} {
        padding: 60px 0px;
    }

    &:after {
        content: "";
        background: $black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .5;
        z-index: 2;
    }
}
.funfact-wrap {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .funfact-icon {
        height: 100px;
        width: 100px;
        background: $primary;
        border-radius: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .odometer {
        display: block;
        line-height: 1;
        font-size: 46px;
        letter-spacing: 0.5px;
        font-weight: 600;
        color: $white;
        margin-bottom: 10px;

        @media #{$small-mobile} {
            font-size: 40px;
        }
    }

    & .title {
        display: block;
        line-height: 1;
        letter-spacing: 0.5px;
        font-weight: 600;
        color: $white;
        font-size: 16px;
        margin-bottom: 0;
    }
}