/* ======== 4.7 Pagination CSS ========*/
.pagination-cr {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
        margin-right: 10px;
        &:first-child {
            margin-left: 0;
            margin-right: 15px;
            a {
                width: auto;
                border: none;
                text-transform: uppercase;
                display: inline-block;

                &:hover {
                    background: none;
                    color: $primary;
                }

                i {
                    font-size: 14px;
                    padding-right: 5px;
                }
            }
        }
        &:last-child {
            margin-right: 0;
            margin-left: 15px;
            a {
                width: auto;
                border: none;
                text-transform: uppercase;
                display: inline-block;
                i {
                    font-size: 14px;
                    padding-left: 5px;
                }

                &:hover {
                    background: none;
                    color: $primary;
                }
            }
        }

        a{
            height: 45px;
            width: 45px;
            line-height: 45px;
            display: flex;
            justify-content: center;
            border: 1px solid #ebebeb;
            border-radius: 3px;
            font-weight: 600;
            font-size: 14px;
            color: $headings-color;
            &:hover {
                color: $white;
                border-color: $primary;
                background: $primary;
            }
        }

        &.active{
            a{
                color: $white;
                background: $primary;
                border-color: $primary;
            }
        }

    }
}
