/*----------------------------------------*/
/*  03. Hero CSS
/*----------------------------------------*/
.hero-slide-item{
    position: relative;
    display: flex;
    align-items: center;
    height: 690px;
    @media #{$tablet-device} {
        height: 600px;
    }
    @media #{$large-mobile} {
        height: 400px;
    }
    @media #{$extra-small-mobile} {
        height: 350px;
    }
}
.hero-slide-bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.hero-slide-content{
    position: relative;
    z-index: 99;
    & .sub-title {
        font-weight: 400;
        line-height: 1.16;
        color: $white;
        margin-bottom: 20px;
        display: block;
        font-size: 18px;
    }
    & .title{
        color: $white;
        font-size: 72px;
        line-height: 1.05;
        margin-bottom: 10px;
    }
    & p {
        font-size: 16px;
        line-height: 1.937;
        max-width: 450px;
        margin-bottom: 35px;
        color: $white;
    }

    @media #{$large-mobile} {
        & .title {
            font-size: 36px;
            margin-bottom: 15px;
        }
        & p {
            margin-bottom: 15px;
        }
    }

    @media #{$extra-small-mobile} {

        & .sub-title {
            margin-bottom: 15px;
            font-size: 16px;
        }

        & .title {
            font-size: 30px;
            margin-bottom: 10px;
        }

        & p {
            font-size: 14px;
            line-height: 1.8;
        }
    }
}
.hero-slider{

    // Swiper Navigation
    .main-slider-nav{
        cursor: pointer;
        background: rgba(0,0,0,0.25);
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: $white;
        opacity: 0;
        visibility: hidden;
        transition: $transition-base;
        font-size: 28px;
        &::after{
            display: none;
        }
        &:hover{
            color: $primary;
        }
        // Responsive
        @media #{$tablet-device, $desktop-device}{
            font-size: 22px;
        }
        // @media #{$large-mobile}{
        //     width: 50px;
        //     height: 50px;
        //     line-height: 50px;
        //     font-size: 20px;
        // }        
        // @media #{$extra-small-mobile}{
        //     width: 40px;
        //     height: 40px;
        //     line-height: 40px;
        //     font-size: 20px;
        // }

        @media #{$large-mobile} {
            display: none;
        }
    }
    .home-slider-prev{
        left: 30px;        
        @media #{$small-mobile}{
            left: 5px;
        }
    }
    .home-slider-next{
        right: 30px;
        &::after{
            display: none;
        }
        @media #{$small-mobile}{
            right: 5px;
        }
    }
    &:hover{
        .main-slider-nav{
            opacity: 1;
            visibility: visible;
        }
    }

    .home-slider-prev, .home-slider-next {
        margin-top: 0;
        transform: translateY(-50%);
    }

    // Swiper Pagination

    .swiper-pagination {
        position: absolute;
        bottom: 20px;
        display: none;

        @media #{$large-mobile} {
            display: block;
        }
    }
}
.hero-slide-content {
    & > * {
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-name: fadeOutUp;
    }
}
.swiper-slide-active{
    & .hero-slide-content {

        // All Element Selector
        & > * {
            animation-name: fadeInUp;
            
            // Child Selector
            &:nth-child(1) {
                animation-delay: 0.5s;
            }
            &:nth-child(2) {
                animation-delay: 1s;
            }
            &:nth-child(3) {
                animation-delay: 1.5s;
            }
            &:nth-child(4) {
                animation-delay: 2s;
            }
            &:nth-child(5) {
                animation-delay: 2.5s;
            }
            &:nth-child(6) {
                animation-delay: 3s;
            }
            
        }
    }
}