/* ======== 2.3 Mobile Menu ========*/
// Mobile Menu Wrapper
.mobile-menu-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: $transition-base;
    transition-duration: 700ms;
    z-index: 9999;
    &.open{
        opacity: 1;
        visibility: visible;
        & .mobile-menu-inner {
            transform: none;
        }
    }
}

// Mobile Menu Inner
.mobile-menu-inner {
    width: 100%;
    height: 100%;
    width: 350px;
    background-color: $gray-400;
    position: relative;
    transform: translateX(calc(100% - 50px));
    transition: $transition-base;
    transition-duration: 700ms;
    margin-left: auto;
    position: relative;
    padding: 20px 0 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
    & .btn-close-bar{
        display: flex;
        justify-content: flex-start;
        font-size: 50px;
        padding-left: 20px;
        color: $headings-color;
        opacity: 1;
        & i{
            cursor: pointer;
            transform: rotate(0deg);
            transition: $transition-base;
            &:hover{
                color: $primary;
                transition: $transition-base;
                transform: rotate(90deg);
            }
        }
    }

    .widget-social {
        justify-content: flex-start;
        padding-left: 40px;
    }
}

// Main Mobile Menu
.mobile-menu {
    text-align: start;
    padding: 10px 40px 30px;
    & > .has-children {
        & > a {
            & i {
                transform: rotate(0deg);
                transition: $transition-base;
            }
        }
        & .dropdown {
            
            & a {
                font-size: 16px;
                padding-top: 5px;
                display: block;
                padding-left: 15px;
            }
        }
        &.active{
            & > a {
                color: $primary;
                & i {
                    transform: rotate(180deg);
                    transition: $transition-base;
                }
            } 
        }
    }
    & > li {
        margin-bottom: 15px;
        & > a {
            color: $headings-color;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            &:hover {
                color: $primary;
            }
        }
    }
}

// Contact Link
.contact-links {
    text-align: left;
    padding: 0 20px 0 40px;
    margin-bottom: 40px;

    li{
        color: $secondary;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
        a{
            color: $secondary;
            &:hover{
                color: $primary;
            }
        }
        i{
            color: $primary;
            margin-right: 10px;
            font-size: 22px;
        }
    }
}
