/* ======== 2.2 Header Bottom ========*/
.header-bottom{
    background: transparent;
    &.header-sticky{
        min-height: 100px;
        @media #{$extra-small-mobile} {
            min-height: 80px;
        }
        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            background-color: $white;
            width: 100%;
            animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
            box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2);
            z-index: 999;
        }
    }
}
/*-- Logo --*/
.header-logo{
    & a {
        & img{
            max-width: 100%;
        }
    }
}
/*-- Main Menu --*/
.main-menu {
    display: flex;
    justify-content: flex-end;
    & > ul {
        display: flex;
        flex-wrap: wrap;
        & > li {
            position: relative;
            & + li {
                margin-left: 30px;
                @media #{$laptop-device} {
                    margin-left: 40px;
                }
            }
            & > a {
                font-size: 15px;
                line-height: 100px;

                text-decoration: none;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                font-weight: 600;
                &:hover,
                &.active {
                    color: $primary;
                }
            }
            &.has-children{
                & > a {
                    &::after{
                        content: "\f107";
                        font-family: 'fontAwesome';
                        padding-left: 5px;
                    }
                }
            }
            &:hover{
                & .sub-menu {
                    margin-top: 0;
                    opacity: 1;
                    visibility: visible;
                    transition: $transition-base;
                }
            }
        }
        & .sub-menu {
            background-color: $gray-400;
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            right: auto;
            width: 230px;
            padding: 15px 0;
            border-bottom: 3px solid $primary;
            margin-top: 20px;
            opacity: 0;
            visibility: hidden;
            transition: $transition-base;
            z-index: 9;
            &::before {
                content: "";
                position: absolute;
                top: -10px;
                left: 25px;
                text-align: center;
                margin: 0 auto;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 7.5px 10px 7.5px;
                border-color: transparent transparent $gray-400 transparent;
            }
            & li {
                & a {
                    font-weight: 400;
                    letter-spacing: 0.5px;
                    display: block;
                    padding: 5px 20px;
                    text-transform: uppercase;
                }
            }
        }
    }
}
/*-- Mobile Menu Bar --*/
.main-menu-right-side{
    justify-content: flex-end;
    & a {
        font-size: 20px;
        line-height: 100px;
        color: #3d3d3d;
        
        &:hover {
            color: $primary;
        }
        
        @media #{$extra-small-mobile} {
            line-height: 80px;
        }
    }
}