/* ======== 4.4 Testimonial CSS ========*/
.testimonial {
    & .text {
        position: relative;
        & p {
            background: $gray-400;
            padding: 30px 27px;
            font-style: italic;
            font-size: 14px;
            line-height: 24px;
            position: relative;
            margin-bottom: 25px;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            border-width: 11px 10px 6px 17px;
            border-style: solid;
            border-color: #d6d6d6 #d6d6d6 transparent transparent;
        }
    }

    & .thumb {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 30px;

        & img {
            width: 90px;
            height: 90px;
            display: inline-block;
            border-radius: 100%;
            margin-right: 15px;
        }

        & .name {

            & .title {
                font-size: 16px;
                color: $headings-color;
                font-weight: 600;
                display: block;
                line-height: 1;
                margin-bottom: 8px;
            }

            & .sub-title {
                font-size: 12px;
                color: #666666;
                font-weight: 400;
                display: block;
                line-height: 1;
            }
        }

    }
}