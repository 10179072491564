/* ======== 6.2 Footer Bottom CSS ========*/
.footer-bottom {
    padding: 15px 0;
    border-top: 1px solid #8b8b8b;
}
.copyright-content { 
    & p {
        color: $white;

        & a {
            color: $white;

            &:hover {
                color: $primary;
            }
        }
    }
}