/* ======== 4.6 Breadcrumb CSS ========*/
.breadcrumb-bg {
    background-image: url(../images/bg/breadcrumb-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: $primary;
        opacity: .6;
    }
}
.cr-breadcrumb-area {
    padding: 60px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$large-mobile} {
        flex-direction: column;

        & .title {
            text-align: center;
            padding-bottom: 10px;
        }
    }

    & .title {
        color: $white;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 1;
        margin-bottom: 0px;
        @media #{$small-mobile} {
            font-size: 26px;
        }
    }

    & .breadcrumb-list {
        z-index: 1;

        display: flex;
        flex-wrap: wrap;

        & li {
            color: $white;
            display: flex;
            align-items: center;

            &:not(:first-child):before {
                content: "/";
                padding: 0 5px;
            }

            & a {
                color: $white;
                transition: $transition-base;
                font-size: 16px;
                font-weight: 400;

                &:hover {
                    text-decoration: underline;
                    transition: $transition-base;
                }
            }
        }
    }
}