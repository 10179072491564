/* ======== 2.1 Header Top ========*/
.header-top{
    padding-top: 10px;
    padding-bottom: 10px;
    @media #{$tablet-device, $large-mobile, $small-mobile} {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @media #{$tablet-device, $large-mobile} {
        display: none;
    }
}
/*-- Links --*/
.header-top-links{
    display: flex;
    flex-wrap: wrap;
    @media #{$tablet-device, $large-mobile, $small-mobile} {
        justify-content: center;
        margin-bottom: 10px;
    }
    li{
        color: $white;
        padding: 0 30px;
        border-left: 1px solid #41414a;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @media #{$desktop-device, $tablet-device, $large-mobile} {
            padding: 0 12px;
            font-size: 13px;
        }
        @media #{$large-mobile} {
            padding: 0 12px;
            font-size: 13px;
            border-left: 0px;
            margin-bottom: 5px;
        }
        &:first-child{
            border-left: 0px;
            padding-left: 0px;
        }
        a{
            color: $white;
            &:hover{
                color: $primary;
            }
        }
        i{
            color: $primary;
            margin-right: 10px;
            font-size: 22px;
        }
    }
}
/*-- Header Top Action --*/
.header-top-action{
    position: relative;
    @media #{$tablet-device, $large-mobile, $small-mobile} {
        width: 290px;
        margin: 0 auto;
    }
    .action-search{
        height: 40px;
        line-height: 40px;
        padding-left: 20px;
        border: 1px solid #41414a;
        border-radius: 3px;
        color: $white;
        font-size: 14px;
        font-weight: 300;
        padding-right: 35px;
        background: transparent;
        outline: 0;
        transition: $transition-base;
        width: 250px;
        @media #{$tablet-device, $large-mobile, $small-mobile} {
            width: 100%;
        }
        &:focus{
            border: 1px solid $primary;
            transition: $transition-base;
        }
        &::placeholder {
            color: $white;
            opacity: 1;
        }
    }
    .action-btn{
        position: absolute;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
        right: 0;
        color: $white;
        font-size: 16px;
        border: none;
        padding-right: 15px;
        padding-left: 5px;
        background: transparent;
        transition: $transition-base;
        &:hover{
            color: $primary;
            transition: $transition-base;
        }
    }
}