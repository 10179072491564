/* ======== 5.1 About CSS ========*/
.feature-box-wrapper {
    display: flex;
    position: relative;
    overflow: hidden;
    
    & .feature-box-icon{
        height: 100px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1000px;
        text-align: center;
        line-height: 70px;
        padding: 15px;
        border: 2px solid $primary;
        transition: .3s;

        @media #{$small-mobile} {
            height: 90px;
            width: 90px;
        }
    }

    & .feature-content {
        margin-left: 20px;

        width: 65%;

        @media #{$desktop-device} {
            width: 58%;
        }

        @media #{$tablet-device, $large-mobile} {
            width: 75%;
        }

        & .title {
            font-size: 20px;
            font-weight: 500;
            color: $headings-color;
        }
    }

    &.feature-box-border {
        margin-bottom: 0px;
        padding-bottom: 0px;

        &:not(:last-child):before{
            content: '';
            position: absolute;
            background: $gray-300;
            left: 12%;
            bottom: 0;
            width: 100%;
            height: 1px;
        }

        & .feature-content {
            width: 75%;
            margin-left: 20px;
        }
    }

    &.feature-box-column {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        & .feature-content {
            width: 300px;
            margin-left: 0px;
        }
    }

    &:hover {
        .feature-box-icon {
            background-color: $primary;
            border-color: $primary;
            transition: .3s;

            img {
                filter: brightness(0) invert(1);
                transition: .3s;
            }
        }
    }
}
.about-timeline-spacing-bottom{
    margin-bottom: 60px;
    padding-bottom: 53px;
}
.about-timeline-spacing-top{
    margin-top: 60px;
    padding-top: 60px;
}
.about-content-area {
    & .title {
        font-size: 36px;
        font-weight: 700;
        line-height: 1.16;

        margin-bottom: 0;
        margin-top: -7px;

        color: $black;
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 10px;
        @media #{$large-mobile} {
            font-size: 30px;
        }
        @media #{$extra-small-mobile} {
            font-size: 26px;
        }

        &:before {
            position: absolute;
            bottom: 0;
            content: '';
            width: 100px;
            height: 2px;
            background: $primary;
        }
    }
}
.about-image {
    & img {
        width: 100%;
    }
}